import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { ModuleButton, ModuleCurrentSession, ModulesContainer, ModulesHeader } from "./Modules.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useNavigate } from "react-router-dom";
import { MetronomeDivider } from "../Metronome/Metronome.styled";
import { ICON_ARROW_LEFT_COMPONENT, ICON_ARROW_RIGHT_COMPONENT } from "../../../../components/common/Icons";
import { Clock } from "./Clock";
import { AlarmClockTime } from "../../../../constants";
import { LessonStore } from "../../../../stores/lessons/lesson.store";
import { convertSecondsToMS } from "../../../../helpers";

export const Modules = observer(() => {
	const { lessonStore, lessonProgressStore, alarmClockStore, nextLessonStore } = useStores();
	const navigate = useNavigate();

	// Время урока
	const [totalSecond, setTotalSecond] = useState(0);
	const [secondsLeft, setSecondsLeft] = useState(0);

	// ID таймера
	let idInterval = useRef(0);

	// Общее количество секунд в уроке
	let totalSecondRef = useRef(0);

	// Предыдущий урок
	let previousLesson = useRef<null | LessonStore>(null);

	// Страница смонтирована
	useEffect(() => {
		// Инициализирован таймер
		setTimeout(() => {
			nextLessonStore.setIsInit(true);
		}, 500);

		// Отправляем данные
		return () => {
			clearInterval(idInterval.current);
			nextLessonStore.setIsInit(false);
		};
	}, []);

	// Определяем длинну урока
	useEffect(() => {
		(async () => {
			if (previousLesson.current?.uuid !== lessonStore.uuid) {
				// Отправляем данные по предыдущему уроку

				// Записываем новый урок
				previousLesson.current = JSON.parse(JSON.stringify(lessonStore));
			}

			// Сброс просмотра перемены
			nextLessonStore.close();
			nextLessonStore.setIsView(false);

			switch (lessonStore.currentServiceId) {
				case 1: {
					alarmClockStore.setCurrentTime(AlarmClockTime.First);
					break;
				}
				case 2: {
					alarmClockStore.setCurrentTime(AlarmClockTime.Second);
					break;
				}
				case 3: {
					alarmClockStore.setCurrentTime(AlarmClockTime.Third);
					break;
				}
			}
		})();
	}, [lessonStore.uuid]);

	// Работа таймера при смене времени
	useEffect(() => {
		// Очистка таймера
		clearInterval(idInterval.current);

		// Переводим минуты в секунды
		const totalSeconds = alarmClockStore.currentTime * 60 + getChangeAtLesson();
		totalSecondRef.current = totalSeconds + getChangeAtLesson();

		// Записываем секунды
		setSecondsLeft(totalSeconds);
		setTotalSecond(totalSeconds);

		timerStart();
	}, [alarmClockStore.currentTime, lessonStore.uuid]);

	useEffect(() => {
		// Условие для показа Уведомления о переходе на след.курс
		if (
			!nextLessonStore.isShow &&
			!nextLessonStore.isView &&
			secondsLeft <= getChangeAtLesson() &&
			nextLessonStore.isInit
		) {
			nextLessonStore.setTotalSeconds(getChangeAtLesson());
			nextLessonStore.open();
		}
	}, [secondsLeft]);

	const timerStart = () => {
		idInterval.current = window.setInterval(() => {
			setSecondsLeft((prevState) => {
				if (prevState <= 0) {
					clearInterval(idInterval.current);
					return prevState;
				}

				return prevState - 1;
			});
		}, 1000);
	};

	// Отдаем общее количество секунд в зависимости от сервиса
	const getChangeAtLesson = useCallback((): number => {
		switch (alarmClockStore.currentTime) {
			case AlarmClockTime.First:
				return 5 * 60;
			case AlarmClockTime.Second:
				return 10 * 60;
			case AlarmClockTime.Third:
				return 15 * 60;
		}
	}, [alarmClockStore.currentTime]);

	const handleOnNextModule = async () => {
		const lesson_uuid = lessonProgressStore.next();
		await lessonStore.getByUUID(lesson_uuid);
		navigate(`/cabinet/lessons/${lesson_uuid}`);
	};

	const handleOnPrevModule = async () => {
		const lesson_uuid = lessonProgressStore.prev();
		await lessonStore.getByUUID(lesson_uuid);
		navigate(`/cabinet/lessons/${lesson_uuid}`);
	};

	const handleOnChangeTime = () => {
		alarmClockStore.changeCurrentTime();
	};

	// Процент заливки урока
	const getPercentDurationLesson = useCallback(() => {
		switch (alarmClockStore.currentTime) {
			case AlarmClockTime.First:
				return 50;
			case AlarmClockTime.Second:
				return 75;
			case AlarmClockTime.Third:
				return 100;
			default:
				return 0;
		}
	}, [alarmClockStore.currentTime]);

	// Длина перемены
	const getDurationSchoolBreak = useCallback(() => {
		switch (alarmClockStore.currentTime) {
			case AlarmClockTime.Third:
				return 25;
			case AlarmClockTime.Second:
				return 16.6;
			case AlarmClockTime.First:
				return 8.3;
			default:
				return 0;
		}
	}, []);

	const getMinusPercent = () => {
		switch (alarmClockStore.currentTime) {
			case AlarmClockTime.First:
				return 50;
			case AlarmClockTime.Second:
				return 25;
			case AlarmClockTime.Third:
				return 0;
		}
	};

	const totalSecondsLeft = totalSecond - secondsLeft;
	const updatePercent = Number(((totalSecondsLeft * (100 - getMinusPercent())) / totalSecond).toFixed(2));
	const [, minutes, seconds] = convertSecondsToMS(secondsLeft - getChangeAtLesson());

	return (
		<ModulesContainer>
			<ModulesHeader>
				<ModuleButton left onClick={handleOnPrevModule}>
					<ICON_ARROW_LEFT_COMPONENT />
				</ModuleButton>
				<span>Module {lessonStore.numberModule}</span>
				<ModuleButton right onClick={handleOnNextModule}>
					<ICON_ARROW_RIGHT_COMPONENT />
				</ModuleButton>
			</ModulesHeader>

			<MetronomeDivider />

			<ModuleCurrentSession>Current Session Timer</ModuleCurrentSession>

			<Clock
				percent={updatePercent}
				backgroundPercent={getPercentDurationLesson()}
				lessonBreakPercent={getDurationSchoolBreak()}
				minute={minutes}
				second={seconds}
				onClick={handleOnChangeTime}
			/>
		</ModulesContainer>
	);
});
