import {
	Body,
	CardId,
	Container,
	Content,
	HeaderCard,
	InformationCard,
	Name,
	PhotoBlock,
	Post,
	Quotes,
	Social,
	Title,
} from "./TheTeamPage.styled";

interface TheTeamPageProps {}

export const TheTeamPage = ({}: TheTeamPageProps) => {
	return (
		<>
			<Container isBlack>
				<Body>
					<Title>MUSICABINET | THE FOUNDER</Title>

					<InformationCard>
						<PhotoBlock>
							<img src="/images/team/raul.jpg" srcSet="/images/team/raul@2x.jpg 2x" alt="" />

							<Quotes>
								<p>
									<span>"If you can't explain it to a six year old, you don't understand it yourself."</span>
								</p>

								<p>Albert Einstein</p>
							</Quotes>
						</PhotoBlock>

						<CardId>
							<Name>Rasul Zakarzhaev</Name>

							<Post>
								<span>The Founder of MUSICABINET</span>
								<span>The Author of the Guitar Program</span>
							</Post>

							<Social>
								<li>
									<img src="/images/linkedin.png" srcSet="/images/linkedin@2x.png 2x" alt="" />
									<a href="https://www.linkedin.com/in/rasulzakar/" target="_blank" rel="noreferrer">
										View LinkedIn Profile
									</a>
								</li>
							</Social>

							<HeaderCard>
								Why did I invent
								<br /> MUSICABINET?
							</HeaderCard>

							<Content>
								<p>
									Pursuing my dream of becoming a professional guitar player I have been admitted to the Berklee College
									of Music. Unfortunately soon I realized that even one of the best music colleges in the world wasn’t
									able to give me what I really wanted. I lacked a system…
								</p>

								<p>
									Then an idea struck me… I realized what I wanted to have and what I wanted to give to the professional
									music world.
								</p>

								<p>
									For years, even before launching in June of 2023, my colleagues and I have been developing this truly
									unique system consisting of more than 5,100 COMPLETE LESSONS, collected together into a MUSICABINET |
									Education System.
								</p>

								<p>
									It really doesn’t have rivals on the market — we’ve checked. We are completely different from all of
									them. I see MUSICABINET as a flag, standing out all alone on a new planet — the music planet, which
									you are going to open for yourself.
								</p>

								<p>
									I have developed the guitar program with 1 thought in my mind — I want to make learning to play the
									guitar as easy, quick and convenient as possible. As a result — MUSICABINET was born. BEGINNER,
									ADVANCED & PROFESSIONAL — 3 levels of perfection in no time.
								</p>

								<p>
									I personally use it every single day to practice and upgrade my skills and believe that with the right
									approach and following our methodology you will reach the levels you have long dreamt about.
								</p>

								<p>Welcome to MUSICABINET.</p>
							</Content>
						</CardId>
					</InformationCard>
				</Body>
			</Container>

			<Container>
				<Body>
					<Title>MUSICABINET | MARKETING</Title>

					<InformationCard>
						<CardId>
							<Name>Ayaz Kamalov</Name>

							<Post>
								<span>Marketing Specialist</span>
							</Post>

							<Social>
								<li>
									<img src="/images/linkedin.png" srcSet="/images/linkedin@2x.png 2x" alt="" />
									<a href="https://www.linkedin.com/in/ayazkamalov/" target="_blank" rel="noreferrer">
										View LinkedIn Profile
									</a>
								</li>
							</Social>

							<HeaderCard>Years of perfecting marketing and gaining experience to make a difference</HeaderCard>

							<Content>
								<p>
									I enthusiastically embarked on this transformative journey in August 2023, aligning with the official
									launch of our company in Dubai in June 2023. Being part of this pivotal juncture in our company's
									evolution allows me to actively shape and contribute to its flourishing trajectory right from the
									start.
								</p>

								<p>
									At MUSICABINET, we're not just part of a company; we're pioneers in a realm of innovation that truly
									sets us apart. It's exhilarating to be a member of a team that goes beyond the ordinary, crafting
									something truly unique and groundbreaking. I take pride in our commitment to innovation, recognizing
									its power to drive change and shape the world around us.
								</p>

								<p>
									In the dynamic and competitive global market, with our headquarters situated in Dubai, my focus as a
									Marketing Specialist is to not only establish but fortify our brand presence. MUSICABINET's innovative
									projects not only make a difference, but they redefine industry standards. I am thrilled to play a
									crucial role in connecting with our target audience and steering the growth of MUSICABINET,
									particularly in a region as vibrant as Dubai.
								</p>

								<p>
									I firmly believe in the transformative potential of innovative endeavors, especially in the realm of
									education. In the field of education, innovative projects have the power to make not just one, but
									twice or even triple the impact. It's an exciting prospect to be part of a team that doesn't just
									follow trends but sets them, propelling change and progress in the educational landscape.
								</p>

								<p>
									In essence, MUSICABINET is more than a company; it's a hub of innovation that influences and shapes
									the future. I am genuinely delighted to be a part of this journey, contributing to a vision that
									transcends boundaries and leads the way in redefining what's possible in the world of music and
									education.
								</p>
							</Content>
						</CardId>

						<PhotoBlock>
							<img src="/images/team/ayaz.jpg" srcSet="/images/team/ayaz@2x.jpg 2x" alt="" />

							<Quotes>
								<p>
									<span>"Innovation is the ability to see change as an opportunity – not a threat."</span>
								</p>

								<p>Steve Jobs</p>
							</Quotes>
						</PhotoBlock>
					</InformationCard>
				</Body>
			</Container>
		</>
	);
};
