import { Button, Container, Title } from "./TestMe.styled";

import { ReactComponent as MicrophoneIcon } from "./images/microphone.svg";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { useEffect, useRef } from "react";
import { DetectedVolume } from "./DetectedVolume";

interface TestMeProps {}

export const TestMe = observer(({}: TestMeProps) => {
	const { aiStore } = useStores();

	const audioRef = useRef<HTMLAudioElement>(null);

	useEffect(() => {
		(async () => {
			await aiStore.initRecording();
		})();
	}, []);

	const handleOnToggleRecord = () => {
		if (!aiStore.recorder || aiStore.recordNow) {
			return;
		}

		aiStore.setAudioURL("");
		aiStore.setIsShowCounter(true);

		aiStore.setRecordNow(!aiStore.recordNow);
		aiStore.detectedRecord();
	};

	useEffect(() => {
		if (audioRef.current) {
			if (aiStore.audioURL) {
				audioRef.current.src = aiStore.audioURL;
			} else {
				audioRef.current.src = "";
			}
		}
	}, [aiStore.audioURL]);

	return (
		<Container>
			<Button onClick={handleOnToggleRecord} isRecord={aiStore.recordNow}>
				<MicrophoneIcon />
			</Button>

			<Title>Test Me</Title>

			<DetectedVolume />
		</Container>
	);
});
