import styled from "styled-components";
import { rgba } from "polished";
import { COLORS, fontMixins } from "../../../../../../../styles";

export const Container = styled.div`
	position: absolute;
	inset: 0;
	background: ${rgba(COLORS.Black, 0.65)};
	display: flex;
	align-items: center;
	justify-content: center;
	${fontMixins(120, 700)};
	color: ${COLORS.White};
`;
