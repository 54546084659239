import { action, computed, observable } from "mobx";
import { ScoreItem } from "../../interfaces/scores";
import { ScoreContentStore } from "./score-content.store";
import { ScoreTypeStore } from "./score-type.store";
import { SCORE_TYPE } from "../../constants";
import { LibraryScoreItemStore } from "../library/library-score-item.store";
import { ScoreItemAiMidiStore } from "../score-item-ai-midi";

export class ScoreItemStore implements ScoreItem {
	@observable id = 0;
	@observable score_id = 0;
	@observable score_type_id = 0;
	@observable sort = 0;
	@observable content = new ScoreContentStore(null);
	@observable type = new ScoreTypeStore(null);
	@observable libraries: LibraryScoreItemStore[] = [];
	@observable ai_midi = new ScoreItemAiMidiStore(null);

	@observable video_iframe = "";

	constructor(initialData: ScoreItem | null) {
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isTypeTitle() {
		return this.score_type_id === SCORE_TYPE.TITLE;
	}

	@computed
	get isTypeImage() {
		return this.score_type_id === SCORE_TYPE.IMAGE;
	}

	@action
	fillStore(data: ScoreItem) {
		const { id, score_id, score_type_id, sort, content, type, libraries, ai_midi } = data;

		this.id = id;
		this.score_id = score_id;
		this.score_type_id = score_type_id;
		this.sort = sort;
		this.content = new ScoreContentStore(content);
		this.type = new ScoreTypeStore(type);
		this.libraries = (libraries || []).map((library) => new LibraryScoreItemStore(library));
		this.ai_midi = new ScoreItemAiMidiStore(ai_midi);
	}
}
