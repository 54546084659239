import { Body, Header, Icon, Item, Title } from "./MyDayList.styled";
import { GrandChartMyDayStore } from "../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";
import { observer } from "mobx-react";
import { getIconInstrument, ucFirst } from "../../../../helpers";
import { INSTRUMENT_NAME } from "../../../../constants";
import { Course } from "./Course/Course";
import { Percentage } from "./Percentage/Percentage";
import { Modules } from "./Modules/Modules";
import { GroupLesson } from "./GroupLesson/GroupLesson";
import { Lesson } from "./Lesson/Lesson";
import { ButtonAction } from "./ButtonAction/ButtonAction";

interface MyDayItemProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const MyDayItem = observer(({ grandChartMyDayStore }: MyDayItemProps) => {
	console.log(grandChartMyDayStore.lastLessonData);

	return (
		<Item theme={grandChartMyDayStore.service.slug}>
			<Header>
				<Icon>{getIconInstrument(grandChartMyDayStore.instrument.icon as INSTRUMENT_NAME)}</Icon>

				<Title>
					{ucFirst(grandChartMyDayStore.nameInstrument)} {grandChartMyDayStore.service.nameOther} for today
				</Title>
			</Header>

			<Body>
				<Course grandChartMyDayStore={grandChartMyDayStore} />
				<Percentage />
				<Modules
					grandChartMyDayStore={grandChartMyDayStore}
					grand_chart_modules={grandChartMyDayStore.grand_chart_modules}
				/>
				<GroupLesson grandChartMyDayStore={grandChartMyDayStore} />
				<Lesson grandChartMyDayStore={grandChartMyDayStore} />
				<ButtonAction grandChartMyDayStore={grandChartMyDayStore} />
			</Body>
		</Item>
	);
});
