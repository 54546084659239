import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../styles";
import { rgba } from "polished";
import { ServiceName } from "../../constants";

export const HistoryPageContainer = styled.div`
	position: relative;
	background: ${COLORS.White};
	border-radius: 10px;
	padding: 20px 40px 70px;
`;

export const HistoryPageBack = styled.button`
	position: absolute;
	top: 15px;
	left: 20px;
	background: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;

	svg {
		path {
			stroke: ${COLORS.Red};
		}
	}
`;

export const HistoryPageTitle = styled.div`
	display: flex;
	column-gap: 25px;
	${fontMixins(18)};
	font-weight: 700;
	line-height: 30px;
	padding: 0 0 0 42px;
	margin-bottom: 21px;
`;

export const QuotesBlock = styled.div`
	${fontMixins(12)};
	line-height: 18px;
	color: ${rgba(COLORS.Dark_Gray, 0.6)};
`;

interface HistoryItemContainerI {
	theme: ServiceName;
}

export const HistoryItemContainer = styled.div<HistoryItemContainerI>`
	margin-bottom: 40px;

	${(props) =>
		props.theme &&
		css`
			${HistoryItemNameService} {
				color: ${getColorByService(props.theme)};
			}

			${ItemContainer} {
				div[data-name-lesson] {
					color: ${getColorByService(props.theme)};
				}

				svg {
					circle:nth-child(2) {
						stroke: ${getColorByService(props.theme)};
					}
				}
			}

			${HistoryItemIcon} {
				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}

					&#keys {
						path {
							&:nth-child(2),
							&:nth-child(4),
							&:nth-child(8),
							&:nth-child(10),
							&:nth-child(12) {
								fill: ${getColorByService(props.theme)};
							}
						}
					}
				}
			}
		`}
`;

export const HistoryItemDate = styled.div`
	${fontMixins(14)};
	color: ${COLORS.Dark_Gray};
	margin-bottom: 40px;
`;

export const HistoryItemNameService = styled.div`
	display: flex;
	column-gap: 30px;
	margin-bottom: 5px;

	span {
		position: relative;
		top: 5px;
		${fontMixins(18, 700)};
		line-height: 22px;
	}
`;

export const HistoryItemIcon = styled.div`
	svg {
		width: 40px;
		height: 41px;
	}
`;

export const HistoryItemNameGroup = styled.div`
	${fontMixins(18)};
	line-height: 25px;
	color: ${COLORS.Dark_Gray};
	margin: 0 10px 10px 70px;
`;

export const ItemContainer = styled.div`
	display: grid;
	grid-template-columns: 60px 40px 1fr 150px;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
`;

export const ItemDate = styled.div`
	${fontMixins(12)};
	color: ${COLORS.Dark_Gray};
	width: 60px;
	margin-right: 10px;
`;

export const ItemName = styled.div`
	${fontMixins(12)};
	color: ${COLORS.Dark_Gray};
	margin-left: 10px;
`;

export const ItemStatistics = styled.div`
	${fontMixins(10)};
	color: ${COLORS.Dark_Gray};

	span {
		font-weight: 700;
	}
`;
