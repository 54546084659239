import { action, computed, makeObservable, observable } from "mobx";
import { AiBpm } from "../../constants";
import { StringNull } from "../../interfaces";

export class AiStore {
	@observable chosenBPM: AiBpm = AiBpm.Bpm60;
	@observable recordNow = false;

	@observable recorder: MediaRecorder | null = null;
	@observable chunksBlob: Array<Blob> = [];
	@observable audioURL: StringNull = null;
	@observable audioBlob: Blob | null = null;
	@observable recordVolume = 0;
	@observable stream: MediaStream | null = null;
	@observable isShowCounter = false;

	constructor() {
		makeObservable(this);
	}

	async initRecording() {
		if (this.recorder instanceof MediaRecorder) {
			return;
		}

		await navigator.mediaDevices
			.getUserMedia({
				audio: true,
			})
			.then((stream) => {
				this.recorder = new MediaRecorder(stream);

				this.stream = stream;

				this.recorder.ondataavailable = (e) => {
					this.pushChunks(e.data);
				};

				this.recorder.onstop = (e) => {
					const blob = new Blob(this.chunksBlob, { type: "audio/ogg; codecs=opus" });
					this.clearChunks();

					const audioURL = window.URL.createObjectURL(blob);

					this.setAudioBlob(blob);
					this.setAudioURL(audioURL);
				};
			})
			.catch((errStream) => console.log(errStream));
	}

	@action.bound
	setAudioBlob(value: Blob | null) {
		this.audioBlob = value;
	}

	@action.bound
	setIsShowCounter(value: boolean) {
		this.isShowCounter = value;
	}

	@action.bound
	setRecordVolume(value: number) {
		this.recordVolume = value;
	}

	@action.bound
	setAudioURL(audioURL: string) {
		this.audioURL = audioURL;
	}

	@action.bound
	clearAudioUrl() {
		this.audioURL = null;
	}

	@action.bound
	pushChunks(value: Blob) {
		this.chunksBlob.push(value);
	}

	@action.bound
	clearChunks() {
		this.chunksBlob = [];
	}

	@action.bound
	chooseBPM(value: AiBpm) {
		this.chosenBPM = value;
	}

	@action.bound
	setRecordNow(value: boolean) {
		this.recordNow = value;
	}

	@action.bound
	detectedRecord() {
		if (!this.stream) {
			this.setRecordVolume(0);
			return;
		}

		// Level volume
		let audioContext = new AudioContext();
		let analyser = audioContext.createAnalyser();
		let microphone = audioContext.createMediaStreamSource(this.stream);
		let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
		analyser.smoothingTimeConstant = 0.8;
		analyser.fftSize = 1024;
		microphone.connect(analyser);
		analyser.connect(javascriptNode);
		javascriptNode.connect(audioContext.destination);

		javascriptNode.onaudioprocess = () => {
			let array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			let values = 0;

			let length = array.length;
			for (let i = 0; i < length; i++) {
				values += array[i];
			}

			let average = values / length;
			if (this.recordNow) {
				this.setRecordVolume(average);
			} else {
				this.setRecordVolume(0);
			}
		};
	}

	@computed
	get existRecord(): boolean {
		return Boolean(this.audioURL && this.audioURL.length > 0);
	}
}
