import * as React from "react";
import { Container, GoldLine } from "./Matrix.styled";
import { Courses } from "./Courses/Courses";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { GroupLessons } from "./GroupLessons/GroupLessons";
import { GroupLessonView } from "../GroupLessonView/GroupLessonView";

interface MatrixProps {}

export const Matrix = observer(({}: MatrixProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;

	return (
		<Container
			style={{
				gridTemplateRows: `repeat(${selected.grand_chart_courses.length}, auto)`,
			}}
		>
			<Courses />

			{!selected.showGroupLesson &&
				selected.grand_chart_modules.map((grandChartModule) => {
					return selected.grand_chart_courses.map((grandChartCourse) => {
						return (
							<GroupLessons
								key={`${grandChartCourse.id}-${grandChartModule.id}`}
								grandChartModule={grandChartModule}
								grandChartCourse={grandChartCourse}
							/>
						);
					});
				})}

			<GroupLessonView />
		</Container>
	);
});
