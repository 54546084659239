import { action, computed, makeObservable, observable } from "mobx";
import { ModalStore } from "../common";
import { ScoreStore } from "../scores/score.store";
import { ChartStore } from "../charts/chart.store";
import { ScoreItemStore } from "../scores/score-item.store";
import { ChartItemStore } from "../charts/chart-item.store";
import { SCORE_TYPE } from "../../constants";
import { LibraryScoreItemStore } from "../library/library-score-item.store";

export class ViewerStore {
	@observable modal = new ModalStore();
	@observable list: ScoreStore | ChartStore | null = null;
	@observable chooseItemID = 0;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ScoreStore | ChartStore) {
		this.list = values;
	}

	@action.bound
	setChooseItem(id: number) {
		this.chooseItemID = id;
	}

	@computed
	get image() {
		let findItem = "";

		if (this.list && this.list.items) {
			this.list.items.forEach((item) => {
				if (item.id === this.chooseItemID) {
					// TODO убрать перед деплоем
					//findItem = `https://files.musicabinet.com/files/${item.content.image}`;
					findItem = `${process.env.REACT_APP_CORE_FILES}${item.content.image}`;
				}
			});
		}

		return findItem;
	}

	@computed
	get chooseItem(): ScoreItemStore {
		let findItem: ScoreItemStore = new ScoreItemStore(null);

		if (this.list && this.list.items) {
			this.list.items.forEach((item) => {
				if (item.id === this.chooseItemID && item instanceof ScoreItemStore) {
					findItem = item;
				}
			});
		}

		return findItem;
	}

	@computed
	get filteredList(): Array<ScoreItemStore | ChartItemStore> {
		if (!this.list?.items) {
			return [];
		}

		let result: Array<ScoreItemStore | ChartItemStore> = [];

		this.list.items.forEach((item) => {
			if (item instanceof ScoreItemStore && item.score_type_id === SCORE_TYPE.IMAGE) {
				result.push(item);
			}

			if (item instanceof ChartItemStore && item.chart_type_id === 2) {
				result.push(item);
			}
		});

		return result;
	}

	@computed
	get libraries() {
		if (!this.list?.items) {
			return [];
		}

		let result: Array<LibraryScoreItemStore> = [];

		this.list.items.forEach((item) => {
			if (item.id === this.chooseItemID && item instanceof ScoreItemStore && item.libraries.length > 0) {
				result.push(...item.libraries);
			}
		});

		return result;
	}
}
