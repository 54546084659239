import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence } from "framer-motion";
import { Body, ButtonClose, Container, Overlay, Position } from "./ModalPortal.styled";
import { ModalStore } from "../../../stores/common";
import { observer } from "mobx-react";

interface ModalPortalProps {
	modalStore: ModalStore;
	children?: ReactNode;
	transparent?: boolean;
	center?: boolean;
}

export const ModalPortal = observer(
	({ modalStore, children = null, transparent = false, center = false }: ModalPortalProps) => {
		const { isShow } = modalStore;

		// Запрещаем скролл body страницы
		useEffect(() => {
			if (isShow) {
				document.body.classList.add("overflow-hidden");
			} else {
				document.body.classList.remove("overflow-hidden");
			}
		}, [isShow]);

		const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
		};

		const handleOnClose = () => {
			modalStore.close();
		};

		return createPortal(
			<AnimatePresence>
				{isShow && (
					<Overlay
						onClick={handleOnClose}
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
							transition: {
								duration: 0.2,
							},
						}}
						exit={{
							opacity: 0,
							transition: {
								duration: 0.2,
							},
						}}
					>
						<Position
							initial={{
								opacity: 0,
								y: 30,
							}}
							animate={{
								opacity: 1,
								y: 0,
								transition: {
									duration: 0.2,
								},
							}}
							exit={{
								opacity: 0,
								y: 30,
								transition: {
									duration: 0.2,
								},
							}}
						>
							<Container center={center}>
								<ButtonClose onClick={handleOnClose} />
								<Body transparent={transparent} onClick={handleOnClick}>
									{children}
								</Body>
							</Container>
						</Position>
					</Overlay>
				)}
			</AnimatePresence>,
			document.body,
		);
	},
);
