export * from "./button.constants";
export * from "./method-requests.constants";
export * from "./service.constants";
export * from "./instrument.constants";
export * from "./product.constants";
export * from "./products-description.constants";
export * from "./notification.constants";
export * from "./seo.constants";
export * from "./tutorials.constants";
export * from "./pricing-description.constants";
export * from "./filters.constanst";
export * from "./good-description.constants";
export * from "./price-duration.constants";
export * from "./ai";

export enum InputFieldSize {
	DEFAULT = "default",
	SMALL = "small",
}

export const METRONOME_CONST = {
	BPM: "bpm",
	VOLUME: "metronome_volume",
};

export enum AlarmClockTime {
	First = 25,
	Second = 35,
	Third = 45,
}

export enum SCORE_TYPE {
	TITLE = 1,
	IMAGE = 2,
}

export enum PLAYER_CONST {
	VOLUME = "PLAYER_CONST_VOLUME",
}

export enum LibraryTrackType {
	TRACK = "TRACK",
	BASS = "BASS",
	DRUMS = "DRUMS",
	KEYBOARDS = "KEYBOARDS",
}

export enum LibraryTrackTypeId {
	TRACK = 4,
	BASS = 1,
	DRUMS = 2,
	KEYBOARDS = 3,
}

export enum LibraryType {
	COMPOSITION = 1,
	SINGLE = 2,
}

export const SPACE_CONTROL_CURRENT = "SPACE_CONTROL_CURRENT";

export enum SPACE_CONTROL {
	PLAYER = "SPACE_CONTROL_PLAYER",
	METRONOME = "SPACE_CONTROL_METRONOME",
}

export const AUDIO_PLAYER = {
	VOLUME: "audio-player-volume",
};

export enum HEADER_TYPE {
	ABSOLUTE = "absolute",
	RELATIVE = "relative",
}

export const SETTINGS = {
	UUID: "uuid",
};

export const GROUP_LESSON_SELECTED = "selected-group-lesson";

export const USER_CONST = {
	FIRST: "first_enter",
};

export enum SIGN_PAGE {
	SIGN_IN = "SIGN_PAGE_SIGN_IN",
	SIGN_UP = "SIGN_PAGE_SIGN_UP",
	RECOVER = "SIGN_PAGE_RECOVER",
}

export const TRIAL_VERSION = {
	LAST_DATE: "trial_version_last_date",
};
