import styled from "styled-components";
import { rgba } from "polished";
import { COLORS, fontMixins } from "../../../../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-rows: 1fr 25px;
`;

export const Area = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

interface RecordBlockProps {
	width: number;
}

export const RecordBlock = styled.div<RecordBlockProps>`
	position: absolute;
	display: flex;
	align-items: center;
	overflow: hidden;
	justify-content: center;
	inset: 0;
	height: 100%;
	background: ${rgba(COLORS.Red, 0.75)};
	transition: all 0.2s;
	text-transform: uppercase;
	${fontMixins(18, 700)};
	color: ${COLORS.White};
	width: ${(props) => `${props.width}px`};
`;
