import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../../../styles";
import {
	AudioPlayerContainer,
	AudioPlayerDivider,
	AudioPlayerToggle,
	AudioPlayerVolumeBlock,
} from "../../../../pages/LessonPage/Header/AudioPlayer/AudioPlayer.styled";
import { ServiceName } from "../../../../constants";
import { VolumeProgressLine, VolumeRange } from "../../../common/Volume/Volume.styled";
import {
	AudioPlayerBackIcon,
	AudioPlayerPauseButton,
	AudioPlayerPlayButton,
} from "../../../../pages/LessonPage/Header/AudioPlayer/Body/Body.styled";
import {
	MetronomeButton,
	MetronomeButtonStart,
	MetronomeIconVolume,
} from "../../../../pages/LessonPage/Header/Metronome/Metronome.styled";
import { TrackListContainer } from "../../../../pages/LessonPage/Header/AudioPlayer/TrackList/TrackList.styled";
import { SwitcherElement } from "../../../../pages/LessonPage/Header/AudioPlayer/Switcher/Switcher.styled";

interface ViewerContainerI {
	theme: ServiceName;
}

export const ViewerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 250px;
	grid-template-rows: 150px minmax(300px, 1fr);
	gap: 10px;
	color: ${COLORS.Bgrnd};
	width: max(800px, 1800px);

	${(props) => css`
		${AudioPlayerContainer} {
		}

		${TrackListContainer}, ${AudioPlayerToggle}, ${SwitcherElement}, ${AudioPlayerPlayButton}, ${AudioPlayerPauseButton}, ${VolumeProgressLine}, ${MetronomeButton}, ${MetronomeButtonStart} {
			background: ${getColorByService(props.theme)};
		}

		${AudioPlayerDivider} {
			border-left-color: ${getColorByService(props.theme)};
		}

		${AudioPlayerBackIcon}, ${AudioPlayerVolumeBlock}, ${MetronomeIconVolume} {
			svg {
				path {
					fill: ${getColorByService(props.theme)};
					stroke: ${getColorByService(props.theme)};
				}
			}
		}

		${VolumeRange} {
			&::-webkit-slider-thumb {
				background-color: ${getColorByService(props.theme)};
			}
		}

		${ViewerLeftSide} {
			ul {
				li {
					&.active {
						button {
							background: ${getColorByService(props.theme)};
						}
					}
				}
			}
		}
	`}
`;

export const ViewerAudioPlayer = styled.div`
	background: transparent;

	${AudioPlayerContainer} {
		height: 150px;
	}
`;

export const ViewerMetronome = styled.div`
	background: transparent;
	height: 150px;
`;

export const ViewerBody = styled.div`
	background: ${COLORS.White};
	border-radius: 10px;
	padding: 0;
	grid-column: 1/3;
	display: grid;
	grid-template-columns: 1fr 181px;
	column-gap: 30px;
	user-select: none;
`;

export const ViewerLeftSide = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	text-align: center;

	&:after {
		content: "";
		position: absolute;
		right: 0;
		width: 1px;
		height: 100%;
		border-right: 2px solid ${COLORS.Light_Gray};
	}

	ul {
		display: flex;
		flex-direction: column;
		justify-content: center;
		row-gap: 20px;

		li {
			button {
				border: 0;
				padding: 0;
				margin: 0;
				background: ${COLORS.MidGray};
				width: 24px;
				height: 24px;
				border-radius: 50%;
				${fontMixins(12, 500)};
				color: ${COLORS.Dark_Gray};
				cursor: pointer;
			}

			&.active {
				button {
					width: 50px;
					height: 50px;
					${fontMixins(24, 500)};
					color: ${COLORS.White};
				}
			}
		}
	}
`;

export const ViewerRightSide = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	padding-block: 30px;

	picture {
		display: flex;
		height: 285px;
	}

	img {
		inset: 0;
		width: 100%;
		height: 285px;
		object-fit: contain;
	}
`;

export const ContentSide = styled.div`
	display: grid;
	grid-template-columns: 110px 1fr;
	column-gap: 30px;
`;
