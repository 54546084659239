export enum ServiceName {
	School = "school",
	College = "college",
	University = "university",
	Packs = "packs",
	PreBeginner = "pre_begginer",
	PreAdvanced = "pre_advanced",
	PreProfessional = "pre_professional",
	SkillBundles = "skill_bundles",
}

export const mappingService = {
	[ServiceName.School]: "Beginner",
	[ServiceName.College]: "Advanced",
	[ServiceName.University]: "Professional",
	[ServiceName.Packs]: "Packs",
	[ServiceName.PreBeginner]: "Pre-Beginner",
	[ServiceName.PreAdvanced]: "Pre-Advanced",
	[ServiceName.PreProfessional]: "Pre-Professional",
	[ServiceName.SkillBundles]: "Skill Tracks",
};
