import styled from "styled-components";
import { COLORS } from "../../../../../../../../styles";

export const Container = styled.div`
	svg {
		width: 18px;

		path {
			fill: ${COLORS.Dark_Gray_2};
		}
	}
`;
