import styled, { css } from "styled-components";
import { COLORS } from "../../../../styles";

export const Container = styled.div`
	grid-area: matrix;
	display: grid;
	grid-gap: 10px;
	grid-auto-flow: column;
	grid-template-columns: 170px repeat(auto-fill, 220px);
`;

interface GoldLineI {
	crop: boolean;
	duble?: boolean;
}

export const GoldLine = styled.div<GoldLineI>`
	position: absolute;
	right: 5px;
	top: 0;
	background: ${COLORS.Gold};
	width: 230px;
	height: 100%;
	border-radius: 10px;
	z-index: 10;

	${(props) =>
		props.duble &&
		css`
			right: 235px;
		`}

	${(props) =>
		props.crop &&
		css`
			height: 200px;
		`}
`;
