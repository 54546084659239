import { observer } from "mobx-react";
import { Container } from "./AI.styled";
import { useStores } from "../../../../../stores";
import { LeftSide } from "./LeftSide/LeftSide";
import { WorkArea } from "./WorkArea";

export const AI = observer(() => {
	const { lessonStore } = useStores();

	return (
		<Container>
			<LeftSide />
			<WorkArea />
		</Container>
	);
});
