import styled, { css } from "styled-components";
import { COLORS, fontMixin } from "../../../styles";
interface CircleContainerI {
	custom: boolean;
}

export const CircleContainer = styled.div<CircleContainerI>`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	transition: all 0.2s;

	${(props) =>
		props.custom &&
		css`
			${CircleMainBackground} {
				fill: transparent !important;
			}
		`}

	&:hover {
	}
`;

export const SvgContainer = styled.svg``;

export const CircleBackground = styled.circle`
	fill: none;
	stroke: ${COLORS.MidGray};
`;

export const CircleProgress = styled.circle`
	fill: none;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke: ${COLORS.Gold};
`;

export const CircleMainBackground = styled.circle`
	fill: ${COLORS.Gold};
`;

interface DataInsideI {
	color: string;
}

export const DataInside = styled.div<DataInsideI>`
	position: absolute;
	z-index: 100;
	${fontMixin({ size: 12, weight: 400 })};
	text-align: center;

	${(props) =>
		["#D8D8D8", "#FFFFFF"].includes(props.color) &&
		css`
			color: ${COLORS.Dark_Gray} !important;
		`}
`;
