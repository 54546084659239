import * as React from "react";
import { useEffect } from "react";
import {
	HistoryItemDate,
	HistoryPageBack,
	HistoryPageContainer,
	HistoryPageTitle,
	QuotesBlock,
} from "./HistoryPage.styled";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { ICON_BACK_COMPONENT } from "../../components/common/Icons";
import { HistoryItem } from "./HistoryItem";
import dayjs from "dayjs";

interface HistoryPageProps {}

export const HistoryPage = observer(({}: HistoryPageProps) => {
	const { settingsStore, historyStore } = useStores();
	const { date } = useParams();
	const navigation = useNavigate();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);

		(async () => {
			if (date) {
				await historyStore.getList(date);
			}
		})();
	}, []);

	const handleOnBack = () => navigation("/cabinet/my-statistics/");

	return (
		<HistoryPageContainer>
			<HistoryPageBack onClick={handleOnBack}>
				<ICON_BACK_COMPONENT />
			</HistoryPageBack>
			<HistoryPageTitle>
				History
				<QuotesBlock>
					If you have spend less than a minute in a lesson,
					<br /> it won't be shown in the below stats.
				</QuotesBlock>
			</HistoryPageTitle>

			<HistoryItemDate>{dayjs(date).format("D MMMM YYYY")}</HistoryItemDate>

			{Object.keys(historyStore.list).map((key, index) => {
				return <HistoryItem key={index} date={date || ""} item={historyStore.list[key]} />;
			})}
		</HistoryPageContainer>
	);
});
