import { PercentageContainer, PercentageCurrent, PercentageDescription } from "./Percentage.styled";

interface PercentageProps {}

export const Percentage = ({}: PercentageProps) => {
	return (
		<PercentageContainer>
			<PercentageCurrent>0%</PercentageCurrent>
			<PercentageDescription>of your daily plan completed</PercentageDescription>
		</PercentageContainer>
	);
};
