import { Container, Divider } from "./SidebarAI.styled";
import { BpmList } from "./BpmList";
import { SampleButton } from "./SampleButton";
import { RecordBlock } from "./RecordBlock";

interface SidebarAIProps {}

export const SidebarAI = ({}: SidebarAIProps) => {
	return (
		<Container>
			<BpmList />
			<Divider />
			<SampleButton />
			<RecordBlock />
		</Container>
	);
};
