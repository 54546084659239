import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const Container = styled.div`
	margin: 0 0 0 40px;
	${fontMixins(14, 400)};
`;

export const Header = styled.div`
	display: flex;
	column-gap: 12px;
	align-items: center;
	height: 30px;
`;

export const NameSort = styled.div`
	color: ${COLORS.Dark_Gray_2};
`;

export const Item = styled.button`
	display: flex;
	align-items: center;
	column-gap: 4px;
	margin: 0;
	padding: 0;
	border: 0;
	background: transparent;
	${fontMixins(14, 400)};
	cursor: pointer;
`;
