import { Container } from "./WaveRecord.styled";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../../../../../../stores";
import { RecordBlock } from "../WorkArea.styled";

interface WaveRecordProps {}

export const WaveRecord = observer(({}: WaveRecordProps) => {
	const { aiStore } = useStores();

	const containerRef = useRef<HTMLDivElement>(null);

	const [timer, setTimer] = useState(0);
	const [record, setRecord] = useState(false);

	useEffect(() => {
		if (aiStore.recordNow && !aiStore.isShowCounter) {
			startTimer();
		} else {
		}
	}, [aiStore.recordNow, aiStore.isShowCounter]);

	const startTimer = () => {
		setTimeout(() => {
			setTimer((prevState) => {
				const currentState = prevState + 0.1;

				if (currentState <= 20) {
					startTimer();
				} else {
					return 0;
				}

				return currentState;
			});
		}, 100);
	};

	const getWidth = () => {
		if (containerRef.current) {
			const element: HTMLDivElement = containerRef.current.parentNode as HTMLDivElement;

			return (timer * element.offsetWidth) / 20;
		}

		return 0;
	};

	if (!timer) {
		return null;
	}

	return (
		<Container ref={containerRef}>
			<RecordBlock width={getWidth()}>Recording...</RecordBlock>
		</Container>
	);
});
