export const TUTORIALS_LINKS: Array<{ title: string; link: string }> = [
	{
		title: "Welcome Video",
		link: "https://edu.musicabinet.com/iframe/tutorial/1",
	},
	{
		title: "Quick Start",
		link: "https://edu.musicabinet.com/iframe/tutorial/2",
	},
	{
		title: "Onboarding Tutorial",
		link: "https://edu.musicabinet.com/iframe/tutorial/welcome1",
	},
	{
		title: "Profile page and Navigation",
		link: "https://edu.musicabinet.com/iframe/tutorial/3",
	},
	{
		title: "My Subscriptions",
		link: "https://edu.musicabinet.com/iframe/tutorial/4",
	},
	{
		title: "My Day",
		link: "https://edu.musicabinet.com/iframe/tutorial/5",
	},
	{
		title: "My Statistics",
		link: "https://edu.musicabinet.com/iframe/tutorial/6",
	},
	{
		title: "Trial Period",
		link: "https://edu.musicabinet.com/iframe/tutorial/7",
	},
	{
		title: "Pricing Page & Subscription Purchase",
		link: "https://edu.musicabinet.com/iframe/tutorial/8",
	},
	{
		title: "GRAND CHART",
		link: "https://edu.musicabinet.com/iframe/tutorial/9",
	},
	{
		title: "Backing Tracks Player",
		link: "https://edu.musicabinet.com/iframe/tutorial/10",
	},
	{
		title: "Metronome",
		link: "https://edu.musicabinet.com/iframe/tutorial/11",
	},
	{
		title: "Timers",
		link: "https://edu.musicabinet.com/iframe/tutorial/12",
	},
	{
		title: "Lessons Navigation",
		link: "https://edu.musicabinet.com/iframe/tutorial/13",
	},
	{
		title: "Education System Overview",
		link: "https://edu.musicabinet.com/iframe/tutorial/14",
	},
	{
		title: "Why is our Education System so Efficient?",
		link: "https://edu.musicabinet.com/iframe/tutorial/15",
	},
	{
		title: "10 Thousand Hours Concept",
		link: "https://edu.musicabinet.com/iframe/tutorial/16",
	},
	/*{
		title: "REM (Rhythm Exercise Machine)",
		link: "https://edu.musicabinet.com/iframe/tutorial/17",
	},
	{
		title: "SRM (Sight Reading Machine)",
		link: "https://edu.musicabinet.com/iframe/tutorial/18",
	},
	{
		title: "IGM (Improvisational Guide Machine)",
		link: "https://edu.musicabinet.com/iframe/tutorial/19",
	},
	{
		title: "Extra Materials",
		link: "https://edu.musicabinet.com/iframe/tutorial/20",
	},*/
];
