import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

interface ButtonListenProps {
	isActive: boolean;
}

export const ButtonListen = styled.button<ButtonListenProps>`
	border: 0;
	display: flex;
	margin-bottom: 10px;
	cursor: not-allowed;

	${(props) =>
		props.isActive &&
		css`
			cursor: pointer;

			svg {
				circle {
					fill: ${getColorByService(props.theme)};
				}
			}
		`}
`;

export const Title = styled.div`
	display: flex;
	text-align: center;
	${fontMixins(14, 600)};
	line-height: 16px;
	color: ${COLORS.Dark_Gray};
`;
