import {
	Container,
	Description,
	GoodItem,
	GoodsList,
	LeftBottomBig,
	LeftBottomSmall,
	RightTopBig,
	Title,
} from "./UnitingModal.styled";
import { Modal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

import { ReactComponent as StarIcon } from "./star.svg";
import { GrandChartAccess } from "../../../../interfaces";

interface UnitingModalProps {}

export const UnitingModal = observer(({}: UnitingModalProps) => {
	const { grandCharts } = useStores();
	const { selected: grandChart } = grandCharts;

	console.log(grandChart);

	return (
		<Modal modalStore={grandCharts.unitingModal} isShow={grandCharts.unitingModal.isShow}>
			<Container>
				{grandChart.access === GrandChartAccess.FULL && <Title>Congratulations!</Title>}
				{grandChart.access !== GrandChartAccess.FULL && <Title>{grandChart.title}</Title>}

				{grandChart.access === GrandChartAccess.FULL && (
					<Description>
						You have access to the following products :<strong>{grandChart.grandChartUniteds}</strong>. To start
						learning the selected product, click on its icon in the product list in "My Subscriptions".
					</Description>
				)}
				{grandChart.access !== GrandChartAccess.FULL && (
					<Description>
						This product includes: <strong>{grandChart.grandChartUniteds}</strong>. Subscribe to gain access to all of
						them with discount!
					</Description>
				)}

				<GoodsList>
					{grandChart.goods.map((good) => {
						return (
							<GoodItem>
								<img src={good.fullPathCover ?? ""} alt="" />
							</GoodItem>
						);
					})}
				</GoodsList>

				<RightTopBig>
					<StarIcon />
				</RightTopBig>

				<LeftBottomSmall>
					<StarIcon />
				</LeftBottomSmall>

				<LeftBottomBig>
					<StarIcon />
				</LeftBottomBig>
			</Container>
		</Modal>
	);
});
