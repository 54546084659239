import { ButtonListen, Container, Title } from "./ListenToRecord.styled";
import { ReactComponent as ListenIcon } from "./images/listen.svg";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { useState } from "react";

interface ListenToRecordProps {}

export const ListenToRecord = observer(({}: ListenToRecordProps) => {
	const { aiStore, lessonStore } = useStores();
	const [isPlay, setIsPlay] = useState(false);

	const audioPlayer = new Audio();

	const handleOnClick = async () => {
		if (!aiStore.audioURL) {
			return;
		}

		if (isPlay) {
			audioPlayer.pause();
			audioPlayer.currentTime = 0;
			console.log("stop");
			return;
		} else {
			audioPlayer.src = aiStore.audioURL;
			await audioPlayer.play();
			setIsPlay(true);

			audioPlayer.addEventListener("ended", () => {
				setIsPlay(false);
			});
		}
	};

	return (
		<Container>
			<ButtonListen isActive={aiStore.existRecord} theme={lessonStore.currentService} onClick={handleOnClick}>
				<ListenIcon />
			</ButtonListen>

			<Title>
				Listen to
				<br /> the recording
			</Title>
		</Container>
	);
});
