import { action, makeObservable, observable } from "mobx";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { RootStore } from "../index";

export class WebsocketStore {
	@observable connection: null | WebSocket = null;
	@observable connect = false;
	@observable callbacks: { [key: string]: (data: {}) => void } = {};

	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	setIsConnect(value: boolean) {
		this.connect = value;
	}

	@action.bound
	async init() {
		try {
			return new Promise((resolve) => {
				if (this.connect) {
					console.log(`Exist connection.`);
					resolve(`Exist connection.`);
				}

				// Подключаемся к WS
				this.connection = new WebSocket(`${API.wsPath}`);

				// Открываем соединение
				this.connection.onopen = () => {
					console.log(`[OPEN] Success connection.`);
					this.connect = true;
					resolve(`[OPEN] Success connection.`);
				};

				this.connection.onmessage = (e) => {
					Object.keys(this.callbacks).forEach((key) => {
						this.callbacks[key](JSON.parse(e.data));
					});
				};

				// Закрываем соедеинение
				this.connection.onclose = (e) => {
					if (e.wasClean) {
						console.log(`[CLOSE] Connection success close, code=${e.code} reason=${e.reason}.`);
					} else {
						console.log("[ERROR CLOSE] Disconnect.");
					}
				};
			});
		} catch (e) {
			ErrorAPI("init", e);
		}
	}

	@action.bound
	async disconnect() {
		try {
			if (this.connection instanceof WebSocket) {
				this.connection.close();
				this.connect = false;
				this.connection = null;
			}
		} catch (e) {
			console.error(`Error in method disconnect : `, e);
		}
	}

	@action.bound
	addCallback(key: string, callback: (data: {}) => void) {
		this.callbacks[key] = callback;
	}

	@action.bound
	removeCallback(key: string) {
		let callbacks = this.callbacks;

		if (callbacks[key]) {
			delete callbacks[key];
		}

		this.callbacks = callbacks;
	}

	sendMessage = (data: {}) => {
		try {
			if (this.connection) {
				let defaultData = {
					token: localStorage.getItem(API.nameToken),
					id: this.rootStore.authStore.userStore.id,
				};
				this.connection.send(JSON.stringify({ ...data, ...defaultData }));
			} else {
				console.warn(`Error in method sendMessage Connection = NULL`);
			}
		} catch (e) {
			console.error(`Error in method sendMessage : `, e);
		}
	};
}
