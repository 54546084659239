import * as React from "react";
import {
	CircleBackground,
	CircleProgress,
	CircleContainer,
	DataInside,
	SvgContainer,
	CircleMainBackground,
} from "./CircleProgressBar.styled";

interface CircleProgressBarProps {
	strokeWidth: number;
	sqSize: number;
	percentage: number;
	payload: string | number;
	children?: React.ReactNode;
	custom?: boolean;
	color?: string;
}

export const CircleProgressBar = ({
	strokeWidth,
	sqSize,
	percentage,
	payload,
	children = null,
	custom = false,
	color,
}: CircleProgressBarProps) => {
	// SVG centers the stroke width on the radius, subtract out so circle fits in square
	const radius = (sqSize - strokeWidth) / 2;
	// Enclose cicle in a circumscribing square
	const viewBox = `0 0 ${sqSize} ${sqSize}`;
	// Arc length at 100% coverage is the circle circumference
	const dashArray = radius * Math.PI * 2;
	// Scale 100% coverage overlay with the actual percent
	const dashOffset = dashArray - (dashArray * percentage) / 100;

	const getStyle = () => {
		if (color && color !== "transparent") {
			return {
				fill: color,
			};
		}

		return {};
	};

	return (
		<CircleContainer custom={custom}>
			<DataInside data-name-lesson color={color ?? ""}>
				{payload}
			</DataInside>
			<SvgContainer width={sqSize} height={sqSize} viewBox={viewBox}>
				<CircleBackground cx={sqSize / 2} cy={sqSize / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
				<CircleProgress
					cx={sqSize / 2}
					cy={sqSize / 2}
					r={radius}
					strokeWidth={`${strokeWidth}px`}
					// Start progress marker at 12 O'Clock
					transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
					style={{
						strokeDasharray: dashArray,
						strokeDashoffset: dashOffset,
					}}
				/>

				<CircleMainBackground
					cx={sqSize / 2}
					cy={sqSize / 2}
					r={16}
					transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
					style={{ ...getStyle() }}
				/>
			</SvgContainer>
		</CircleContainer>
	);
};
