import { Container } from "./WaveAudio.styled";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { AudioVisualizer } from "react-audio-visualize";
import { getColorByService } from "../../../../../../../styles";

interface WaveAudioProps {}

export const WaveAudio = observer(({}: WaveAudioProps) => {
	const { aiStore, lessonStore } = useStores();
	const visualizerRef = useRef<HTMLCanvasElement>(null);

	const [currentWidth, setCurrentWidth] = useState(1345);

	useEffect(() => {
		const findElement = document.querySelector("[data-continer-wave]");

		if (findElement) {
			//setCurrentWidth(findElement.clientWidth);
		}
	}, []);

	return (
		<Container data-continer-wave>
			{aiStore.audioBlob && !aiStore.recordNow && (
				<AudioVisualizer
					key={currentWidth}
					ref={visualizerRef}
					blob={aiStore.audioBlob}
					width={1345}
					height={375}
					barWidth={1}
					gap={0}
					barColor={getColorByService(lessonStore.currentService)}
				/>
			)}
		</Container>
	);
});
