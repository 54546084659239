import { GrandChartModuleStore } from "../../../../../stores/grand-charts/grand-chart-module.store";
import {
	ModuleArrow,
	ModuleItemBody,
	ModuleItemName,
	ModuleItemProgress,
	ModuleItemProgressPassed,
} from "./Modules.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

interface ModuleItemProps {
	position: number;
	grand_chart_module: GrandChartModuleStore;
	grandChartMyDayStore: GrandChartMyDayStore;
	is_active: boolean;
}

export const ModuleItem = observer(
	({ grand_chart_module, position, is_active, grandChartMyDayStore }: ModuleItemProps) => {
		const { grandChartsMyDayStore } = useStores();
		const navigate = useNavigate();

		const getPercent = () => {
			if (!is_active) {
				return 0;
			}

			/*let timeSystem = 25;

			if (grandChartMyDayStore.service.id === 1) {
				timeSystem = 25;
			} else if (grandChartMyDayStore.service.id === 2) {
				timeSystem = 35;
			} else if (grandChartMyDayStore.service.id === 3) {
				timeSystem = 45;
			}*/

			return 0;

			//const totalPercent =(grandChartMyDayStore.last_group_lesson.count_minute * 100) / timeSystem)

			/*console.log('totalPercent',totalPercent);

		return totalPercent => 100 ? 100 : totalPercent;*/
		};

		const handleOnClick = () => {
			// Получаем урок от модуля
			const findKey = `${grandChartMyDayStore.id}-${grand_chart_module.id}`;

			if (grandChartsMyDayStore.listProgress[findKey]) {
				navigate(`/cabinet/lessons/${grandChartsMyDayStore.listProgress[findKey].lesson_uuid}`);
			}
		};

		return (
			<ModuleItemBody onClick={handleOnClick}>
				{dayjs().format("ddd") === dayjs().startOf("week").weekday(position).format("ddd") && (
					<ModuleArrow left={getPercent()} />
				)}

				<ModuleItemProgress>
					<ModuleItemProgressPassed width={getPercent()} />
				</ModuleItemProgress>
				<ModuleItemName>Module {position}</ModuleItemName>
			</ModuleItemBody>
		);
	},
);
