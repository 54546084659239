import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const ChartsContainer = styled.div`
	border-radius: 10px;
	background: ${COLORS.White};
	color: ${COLORS.Dark_Gray};
	padding-bottom: 50px;
`;

export const ChartsHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${COLORS.Light_Gray};
	height: 50px;
	border-radius: 10px 10px 0 0;
`;

export const ChartsTitleBlock = styled.div`
	//${fontMixins(36)};
	line-height: 46px;
	text-align: center;
	padding: 25px 0 0;
	font-family: "FuturaMediumC";
`;

export const ChartsSubTitleBlock = styled.div`
	${fontMixins(28)};
	line-height: 34px;
	text-align: center;
	margin: 20px 0 10px;
	font-family: "FuturaMediumC";
`;

export const ChartsItemContainer = styled.div`
	padding: 0 50px;
`;

export const ChartsImageBlock = styled.img`
	display: flex;
	width: 100%;
	padding: 0 50px;
	cursor: pointer;
`;

export const ChartsTextBlock = styled.div`
	${fontMixins(28)};
	line-height: 25px;
	margin: 25px 0 10px;
	font-family: "FuturaMediumC";
	padding: 0 50px;
`;
