import { Button, Container } from "./SampleButton.styled";
import { ReactComponent as PlayIcon } from "./images/play.svg";

interface SampleButtonProps {}

export const SampleButton = ({}: SampleButtonProps) => {
	return (
		<Container>
			<Button>
				<span>
					<PlayIcon />
				</span>
				Sample
			</Button>
		</Container>
	);
};
