import { Container, List, MainDays } from "./MyDayList.styled";
import { Sort } from "./Sort/Sort";
import { MyDayItem } from "./MyDayItem";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Empty } from "./Empty/Empty";
import { Filters } from "../../../../pages/MyDayPage/Filters/Filters";

interface MyDayListProps {}

export const MyDayList = observer(({}: MyDayListProps) => {
	const { grandChartsMyDayStore } = useStores();

	return (
		<Container>
			<MainDays>
				<Sort />

				<List>
					{grandChartsMyDayStore.list.map((grandChartMyDayStore) => {
						return <MyDayItem key={grandChartMyDayStore.id} grandChartMyDayStore={grandChartMyDayStore} />;
					})}

					{grandChartsMyDayStore.list.length === 0 && <Empty />}
				</List>
			</MainDays>

			<Filters />
		</Container>
	);
});
