import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

interface ButtonProps {
	isRecord: boolean;
}

export const Button = styled.button<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid ${COLORS.Red};
	background: ${COLORS.Red};
	cursor: pointer;
	transition: all 0.2s;

	&:active {
		transform: scale(0.98);
	}

	${(props) =>
		props.isRecord &&
		css`
			background: ${COLORS.White};

			svg {
				path {
					stroke: ${COLORS.Red};

					&:last-child {
						fill: ${COLORS.Red};
					}
				}
			}
		`}
`;

export const Title = styled.div`
	${fontMixins(14, 600)};
	color: ${COLORS.Dark_Gray};
	margin-block: 10px 0;
`;
