import { Area, Container } from "./WorkArea.styled";
import { Timer } from "./Timer";
import { WaveRecord } from "./WaveRecord";
import { WaveAudio } from "./WaveAudio";
import { observer } from "mobx-react";

interface WorkAreaProps {}

export const WorkArea = observer(({}: WorkAreaProps) => {
	return (
		<Container>
			<Area>
				{/*<WaveMidi />*/}
				<WaveAudio />
				<WaveRecord />
				<Timer />
			</Area>
		</Container>
	);
});
