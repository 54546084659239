import * as React from "react";
import { useEffect } from "react";
import {
	ContentSide,
	ViewerAudioPlayer,
	ViewerBody,
	ViewerContainer,
	ViewerLeftSide,
	ViewerMetronome,
	ViewerRightSide,
} from "./Viewer.styled";
import { useStores } from "../../../../stores";
import { ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { AudioPlayer } from "../../../../pages/LessonPage/Header/AudioPlayer/AudioPlayer";
import { Metronome } from "../../../../pages/LessonPage/Header/Metronome/Metronome";
import { ServiceName } from "../../../../constants";
import { SidebarAI } from "./SidebarAI";
import { AI } from "./AI/AI";

interface ViewerProps {
	theme: ServiceName;
}

export const ViewerModal = observer(({ theme }: ViewerProps) => {
	const { viewerStore } = useStores();
	const { lessonStore, audioPlayerStore } = useStores();

	useEffect(() => {
		if (!viewerStore.modal.isShow) {
			lessonStore.scores.currentScore.setViewScoreIndex(null);
			lessonStore.scores.currentScore.setIsView(false);

			// Останавливаем трек
			audioPlayerStore.onStop();
		}
	}, [viewerStore.modal.isShow]);

	useEffect(() => {
		window.addEventListener("keyup", handleCloseByEsc);

		return () => {
			window.removeEventListener("keyup", handleCloseByEsc);
		};
	}, []);

	const handleCloseByEsc = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			viewerStore.modal.close();
		}
	};

	const handleOnChangeChoose = async (id: number, indexItem: number) => {
		viewerStore.setChooseItem(id);

		let findItem;

		if (lessonStore.currentService === ServiceName.College) {
			findItem = lessonStore.charts.currentChart?.items.find((item) => item.id === id);
		} else {
			findItem = lessonStore.scores.currentScore?.items.find((item) => item.id === id);
		}

		if (findItem) {
			const library = findItem.libraries[0];

			if (library) {
				audioPlayerStore.setSelectedLibrary(library);
			} else {
				audioPlayerStore.clearSelectedLibrary();
			}
		}

		await audioPlayerStore.loadTrack();
	};

	return (
		<>
			<ModalPortal modalStore={viewerStore.modal} transparent center>
				<ViewerContainer theme={theme}>
					<ViewerAudioPlayer>
						<AudioPlayer isView />
					</ViewerAudioPlayer>

					<ViewerMetronome>
						<Metronome isHorizontal />
					</ViewerMetronome>

					<ViewerBody>
						<ContentSide>
							<ViewerLeftSide>
								<ul>
									{viewerStore.filteredList.map((item, numberItem) => {
										return (
											<li key={item.id} className={`${item.id === viewerStore.chooseItemID ? `active` : ""}`}>
												<button onClick={() => handleOnChangeChoose(item.id, numberItem)}>{numberItem + 1}</button>
											</li>
										);
									})}
								</ul>
							</ViewerLeftSide>

							<ViewerRightSide>
								<picture>
									<img src={viewerStore.image} draggable={false} alt={""} />
								</picture>

								<AI />
							</ViewerRightSide>
						</ContentSide>

						<SidebarAI />
					</ViewerBody>
				</ViewerContainer>
			</ModalPortal>
		</>
	);
});
