import { Container, GrandChartButton, Logotype, NameInstrument, VideoWatch } from "./GrandChartItem.styled";
import { GrandChartStore } from "../../../stores/grand-charts/grand-chart.store";
import { getIconInstrument } from "../../../helpers";
import { IconGrandChart, IconWatchVideo } from "../../../components/common/Icons";
import { MouseEvent } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Status } from "./Status/Status";
import { AutoRenew } from "./AutoRenew/AutoRenew";
import { CurrentStatus } from "./CurrentStatus/CurrentStatus";
import { ButtonActions } from "./ButtonActions/ButtonActions";
import { ChoosePlanModal, ConfirmCancelPlanModal } from "../../../components";

interface GrandChartItemProps {
	grandChart: GrandChartStore;
	isPack?: boolean;
}

export const GrandChartItem = observer(({ grandChart, isPack }: GrandChartItemProps) => {
	const { grandCharts, statisticsStore } = useStores();

	const { instrument } = grandChart;

	const handleOnGrandChart = async (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();

		grandCharts.setSelected(grandChart);
		await grandCharts.selected.get();

		// Еслми гранд чарт объединяющий
		if (grandChart.uniting) {
			grandCharts.unitingModal.open();
			return;
		} else {
			await statisticsStore.getByGrandChart(grandChart.id);
			grandCharts.modal.open();
		}
	};

	const handleOnDetailGrandChart = async () => {
		if (!grandChart.isExistVideo) {
			return;
		}

		grandCharts.setSelected(grandChart);
		await grandCharts.selected.getVideo();
		grandCharts.detailModal.open();
	};

	return (
		<>
			<Container theme={grandChart.service.slug}>
				<Logotype onClick={handleOnGrandChart}>{getIconInstrument(instrument.slug)}</Logotype>
				<NameInstrument onClick={handleOnGrandChart}>{isPack ? grandChart.title : instrument.name}</NameInstrument>

				<GrandChartButton onClick={handleOnGrandChart}>
					<IconGrandChart />
				</GrandChartButton>

				<VideoWatch isExist={grandChart.isExistVideo} onClick={handleOnDetailGrandChart}>
					<IconWatchVideo />
					Watch Video
				</VideoWatch>

				<Status grandChart={grandChart} />

				<AutoRenew grandChart={grandChart} service_name={grandChart.service.slug} />

				<CurrentStatus grandChart={grandChart} />

				<ButtonActions grandChart={grandChart} />
			</Container>

			<ConfirmCancelPlanModal grandChart={grandChart} />
			<ChoosePlanModal grandChart={grandChart} />
		</>
	);
});
