import { LessonCircle, LessonContainer, LessonName } from "./Lesson.styled";
import { CircleProgressBar } from "../../../../common";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";

interface LessonProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const Lesson = ({ grandChartMyDayStore }: LessonProps) => {
	return (
		<LessonContainer>
			<LessonCircle>
				<CircleProgressBar strokeWidth={2} sqSize={40} percentage={0} payload={`${0}%`} />
			</LessonCircle>

			<LessonName>{grandChartMyDayStore.lastLessonData?.lesson_name}</LessonName>
		</LessonContainer>
	);
};
