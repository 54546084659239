import { Container } from "./Timer.styled";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStores } from "../../../../../../../stores";

interface TimerProps {}

export const Timer = observer(({}: TimerProps) => {
	const { aiStore, metronomeStore } = useStores();

	const [isStart, setIsStart] = useState(false);
	const [counter, setCounter] = useState(4);

	useEffect(() => {
		if (aiStore.recordNow && aiStore.isShowCounter) {
			countdownHandler();
		}
	}, [aiStore.recordNow, !aiStore.isShowCounter]);

	const countdownHandler = () => {
		setTimeout(() => {
			setCounter((prevState) => {
				const actualValue = prevState - 1;

				if (actualValue > 0) {
					countdownHandler();
				} else {
					if (aiStore.recorder) {
						aiStore.recorder.start();

						setTimeout(() => {
							if (aiStore.recorder) {
								aiStore.recorder.stop();
								aiStore.setRecordNow(false);
							}
						}, 20000);
					}

					aiStore.setIsShowCounter(false);
					setCounter(4);
				}

				metronomeStore.onPlayTick();

				return actualValue;
			});
		}, 60000 / aiStore.chosenBPM);
	};

	if (!aiStore.isShowCounter) {
		return null;
	}

	if ((!aiStore.isShowCounter && aiStore.recordNow) || aiStore.existRecord) {
		return null;
	}

	return <Container>{counter}</Container>;
});
