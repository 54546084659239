import { Container } from "./BpmList.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";
import { AiBpm } from "../../../../../../constants";
import { ButtonBpm } from "./ButtonBpm";

interface BpmListProps {}

export const BpmList = observer(({}: BpmListProps) => {
	const { aiStore } = useStores();

	return (
		<Container>
			{Object.values(AiBpm)
				.filter((x) => typeof x === "number")
				.map((bpm, index) => (
					<ButtonBpm key={bpm} bpm={bpm as AiBpm} count={index + 1} isActive={bpm === aiStore.chosenBPM} />
				))}
		</Container>
	);
});
