import {
	Actions,
	DescriptionItem,
	DescriptionList,
	Divider,
	ExtraInformation,
	Footer,
	IconGood,
	Item,
	LearnMoreBlock,
	Name,
	Price,
} from "./Goods.styled";
import { observer } from "mobx-react";
import { GoodStore } from "../../../stores/goods";
import { getServiceId } from "../../../styles";
import {
	ICON_COLLEGE_COMPONENT,
	ICON_SCHOOL_COMPONENT,
	ICON_UNIVERSITY_COMPONENT,
} from "../../../components/common/Icons";
import { IconCheck, IconCircle } from "../../../components/ui/Other/Pricing/Products/icons";
import { GoodDescriptionConst, PRICING_DESCRIPTION } from "../../../constants";
import {
	Body,
	CloseButton,
	DescriptionPlan,
	ProductExtraInformation,
	Title,
} from "../../../components/ui/Other/Pricing/Products/Products.styled";
import { MouseEvent, useRef, useState } from "react";
import { PayButton } from "../../../components/common";

interface GoodItemProps {
	goodStore: GoodStore;
	index: number;
	isWhite?: boolean;
}

export const GoodItem = observer(({ goodStore, index, isWhite = false }: GoodItemProps) => {
	const payByCardRef = useRef<HTMLButtonElement>(null);
	const extraInformationRef = useRef<HTMLDivElement>(null);

	const [, setShowExtraInformation] = useState(false);

	const getIcon = () => {
		switch (index) {
			case 0: {
				return <ICON_SCHOOL_COMPONENT />;
			}
			case 1: {
				return <ICON_COLLEGE_COMPONENT />;
			}
			case 2: {
				return <ICON_UNIVERSITY_COMPONENT />;
			}

			default:
				return "none";
		}
	};

	const mapping = {
		1: "Beginner",
		2: "Advanced",
		3: "Professional",
		8: "Pre-Beginner",
		9: "Pre-Advanced",
		10: "Pre-Professional",
	};

	const getPrice = (price: string) => {
		const [dollar, cent] = String(price).split(".");
		return (
			<>
				<span className={"currency"}>$</span>
				{dollar}
				<span>.{cent}</span>
			</>
		);
	};

	const handleOnCloseExtra = (e: MouseEvent) => {
		e.stopPropagation();
		setShowExtraInformation(false);

		if (extraInformationRef.current) {
			extraInformationRef.current.animate(
				[
					{
						height: `${extraInformationRef.current.clientHeight}px`,
						offset: 0,
					},
					{
						height: 0,
						offset: 1,
					},
				],
				{
					duration: 500,
					iterations: 1,
					fill: "forwards",
				},
			);
		}
	};

	const handleOnShowExtra = () => {
		setShowExtraInformation(true);

		if (extraInformationRef.current && payByCardRef.current && payByCardRef.current.offsetParent) {
			extraInformationRef.current.animate(
				[
					{
						height: 0,
						offset: 0,
					},
					{
						height: `${payByCardRef.current.offsetParent.clientHeight - payByCardRef.current.offsetTop}px`,
						offset: 1,
					},
				],
				{
					duration: 500,
					iterations: 1,
					fill: "forwards",
				},
			);
		}
	};

	const descriptions = GoodDescriptionConst[goodStore.keyDescription as "1-1"];

	return (
		<Item theme={getServiceId(goodStore.grand_chart.service_id)} isWhite={isWhite}>
			<IconGood>{getIcon()}</IconGood>
			<Name>
				{mapping[(goodStore.grand_chart.service_id as 1) || goodStore.grand_chart.id]} {}{" "}
			</Name>
			<Price>{getPrice(goodStore.priceFormatted)}</Price>

			<Actions>
				<PayButton goodStore={goodStore} />
			</Actions>

			<DescriptionList>
				{(descriptions?.items || []).map((item) => {
					return (
						<DescriptionItem>
							<IconCheck />
							<span dangerouslySetInnerHTML={{ __html: item }} />
						</DescriptionItem>
					);
				})}
			</DescriptionList>

			<Footer>
				<Divider />
				<ExtraInformation>{descriptions?.includes || ""}</ExtraInformation>

				<LearnMoreBlock onClick={handleOnShowExtra}>
					<IconCircle /> Learn more
				</LearnMoreBlock>
			</Footer>

			<ProductExtraInformation ref={extraInformationRef}>
				<CloseButton onClick={handleOnCloseExtra} />
				<Body>
					<Title serviceName={getServiceId(goodStore.grand_chart.service_id)}>Musicabinet</Title>
					3
					<DescriptionPlan
						dangerouslySetInnerHTML={{
							__html:
								PRICING_DESCRIPTION?.[`${goodStore.grand_chart.instrument_id}-${goodStore.grand_chart.service_id}`],
						}}
					/>
				</Body>
			</ProductExtraInformation>
		</Item>
	);
});
