import { makeObservable, observable } from "mobx";
import { MenuI } from "../../interfaces/menu/menu.interface";
import { ServiceName } from "../../constants";

export class MenuStore {
	@observable list: MenuI[] = [
		{
			name: "Beginner",
			link: "/beginner/guitar",
			type: ServiceName.School,
			children: [
				{
					name: "Guitar",
					link: "/beginner/guitar",
					type: ServiceName.School,
					payload: {
						grandChartId: 1,
					},
				},
				{
					name: "Keyboard",
					link: "/beginner/keyboard",
					type: ServiceName.School,
					payload: {
						grandChartId: 2,
					},
				},
				{
					name: "Saxophone",
					link: "/beginner/saxophone",
					type: ServiceName.School,
					payload: {
						grandChartId: 4,
					},
				},
			],
		},
		{
			name: "Advanced",
			link: "/advanced/guitar",
			type: ServiceName.College,
			children: [
				{
					name: "Guitar",
					link: "/advanced/guitar",
					type: ServiceName.College,
					payload: {
						grandChartId: 3,
					},
				},
				{
					name: "Keyboard",
					link: "/advanced/keyboard",
					type: ServiceName.College,
					payload: {
						grandChartId: 5,
					},
				},
				{
					name: "Saxophone",
					link: "/advanced/saxophone",
					type: ServiceName.College,
					payload: {
						grandChartId: 6,
					},
				},
			],
		},
		{
			name: "Professional ",
			link: "/professional/guitar ",
			type: ServiceName.University,
			children: [
				{
					name: "Guitar",
					link: "/professional/guitar",
					type: ServiceName.College,
					payload: {
						grandChartId: 8,
					},
				},
				{
					name: "Keyboard",
					link: "/professional/keyboard",
					type: ServiceName.College,
					payload: {
						grandChartId: 7,
					},
				},
				{
					name: "Saxophone",
					link: "/professional/saxophone",
					type: ServiceName.College,
					payload: {
						grandChartId: 9,
					},
				},
			],
		},
		{
			name: "Pricing",
			link: "/pricing",
			type: undefined,
			newTab: true,
		},
		{
			name: "Contact",
			link: "/contact",
			type: undefined,
		},
	];

	constructor() {
		makeObservable(this);
	}
}
