import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const ModulesContainer = styled.div`
	grid-column: 1/3;
	display: grid;
	width: calc(100% - 40px);
	margin: 20.5px 40px 0 0;
	grid-auto-flow: column;
	column-gap: 5px;
`;

export const ModuleItemBody = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-inline: 3px;
	border-radius: 8px;
	cursor: pointer;

	&:hover {
		background: ${COLORS.Light_Gray};
	}
`;

export const ModuleItemProgress = styled.div`
	position: relative;
	height: 10px;
	background: ${COLORS.Light_Gray};
	border-radius: 5px;
`;

interface ModuleItemProgressPassedProps {
	width: number;
}

export const ModuleItemProgressPassed = styled.div<ModuleItemProgressPassedProps>`
	position: absolute;
	left: 0;
	top: 0;
	height: 10px;
	border-radius: 5px;
	width: ${(props) => `${props.width}%`};
`;

export const ModuleItemName = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	${fontMixins(12, 300)};
	height: 14px;
	margin-top: 3px;
	margin-bottom: 3px;
`;

interface ModuleArrowProps {
	left: number;
}

export const ModuleArrow = styled.div<ModuleArrowProps>`
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 10px 5px 0 5px;
	border-color: ${COLORS.Gold} transparent transparent transparent;
	bottom: 30px;
	left: ${(props) => `calc(${props.left}% - 8px)`};

	${(props) =>
		props.left === 0 &&
		css`
			left: ${(props) => `0`};
		`}

	${(props) =>
		props.left === 100 &&
		css`
			left: ${(props) => `calc(100% - 10px)`};
		`}
`;

export const ModuleDayWeek = styled.span`
	font-weight: 700;
	margin-left: 3px;
`;
