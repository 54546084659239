import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
	ContainerCollege,
	MethodButton,
	MethodContainer,
	MethodDropDownMenu,
	MethodHeader,
	MethodTextContainer,
	MethodVideoContainer,
	MethodVideoIframeContainer,
	MethodVideoIframeLoading,
	NameMethod,
} from "./Method.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useOutside } from "../../../../hooks";
import { Switcher } from "./Switcher/Switcher";
import { ScoreItem } from "../Scores/ScoreItem";
import { useParams } from "react-router-dom";
import { BURGER_CONSTANTS, BurgerI } from "../../../../constants/burger.constants";
import { ChartsSubTitleBlock, ChartsTitleBlock } from "../Charts/Charts.styled";

interface MethodProps {}

export const Method = observer(({}: MethodProps) => {
	const { lessonStore, viewerStore, viewIframeStore } = useStores();
	const { scores, charts } = lessonStore;
	const { name, sub_title } = scores.currentScore;

	const { uuid } = useParams();
	const [isNote, setIsNote] = useState(false);
	const [isShowBurger, setIsShowBurger] = useState(false);
	const refDropDown = useRef<HTMLUListElement>(null);

	useEffect(() => {
		(async () => {
			await scores.currentScore.getVideo();
		})();
	}, [scores.currentScore.id]);

	useEffect(() => {
		setIsNote(false);
		scores.setCurrentNumber(0);
		charts.setCurrentNumber(0);
	}, [uuid]);

	const handleOnChangeSwitcher = () => setIsNote((prevState) => !prevState);

	const handleOnShow = (idx: number) => {
		viewerStore.setList(lessonStore.scores.currentScore);
		viewerStore.setChooseItem(idx);
		viewerStore.modal.open();
	};

	const handleOnShowBurger = () => setIsShowBurger(true);

	const handleCloseShowBurger = () => setIsShowBurger(false);

	const handleToggleShowBurger = () => {
		isShowBurger ? handleCloseShowBurger() : handleOnShowBurger();
	};

	const handleSetIframe = (item: BurgerI) => {
		if (item.children) {
			return false;
		}

		handleCloseShowBurger();
		viewIframeStore.setIsShow(true);
		viewIframeStore.setUrl(item.link ?? "");
	};

	useOutside(refDropDown, handleCloseShowBurger);

	return (
		<>
			<MethodContainer>
				<MethodHeader>
					<MethodButton onClick={handleToggleShowBurger}>
						<span />
						<span />
						<span />
					</MethodButton>

					<MethodDropDownMenu show={isShowBurger} ref={refDropDown}>
						{BURGER_CONSTANTS.map((item) => {
							return (
								<li key={item.title} onClick={() => handleSetIframe(item)}>
									{item.title}
									{item.children && (
										<ul>
											{item.children.map((subItem) => {
												return (
													<li key={subItem.title} onClick={() => handleSetIframe(subItem)}>
														{subItem.title}
													</li>
												);
											})}
										</ul>
									)}
								</li>
							);
						})}
					</MethodDropDownMenu>

					{!lessonStore.isCollegeGuitar && <NameMethod>Method</NameMethod>}

					{lessonStore.isCollegeGuitar && scores.isExistScoreItems && (
						<Switcher checked={isNote} onChange={handleOnChangeSwitcher} />
					)}
				</MethodHeader>

				<MethodVideoIframeLoading isLoading={scores.currentScore.isLoading}>Loading video...</MethodVideoIframeLoading>

				{isNote ? (
					<>
						<ContainerCollege>
							<ChartsTitleBlock>{name}</ChartsTitleBlock>
							{sub_title.length > 0 && <ChartsSubTitleBlock>{sub_title}</ChartsSubTitleBlock>}
						</ContainerCollege>

						{scores.list[charts.currentNumber] && (
							<div style={{ marginTop: 17 }}>
								{scores.list[charts.currentNumber].items.map((scoreItem, idx) => {
									return <ScoreItem key={scoreItem.id} scoreItem={scoreItem} order={idx} onClick={handleOnShow} />;
								})}
							</div>
						)}
					</>
				) : (
					<>
						{scores.currentScore.video_link && (
							<MethodVideoIframeContainer>
								<iframe src={scores.currentScore.video_link} title={scores.currentScore.name} />
							</MethodVideoIframeContainer>
						)}

						{!scores.currentScore.emptyVideo && !scores.currentScore.video_link && (
							<>
								<MethodVideoContainer dangerouslySetInnerHTML={{ __html: scores.currentScore.video_iframe }} />
							</>
						)}
						<MethodTextContainer dangerouslySetInnerHTML={{ __html: scores.currentScore.content }} />
					</>
				)}
			</MethodContainer>
		</>
	);
});
