import { Container, Header, Item, NameSort } from "./Sort.styled";

interface SortProps {}

export const Sort = ({}: SortProps) => {
	return (
		<Container>
			<Header>
				<NameSort>Sort by:</NameSort>

				<Item>Last Activity</Item>
			</Header>
		</Container>
	);
};
