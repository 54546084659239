import * as React from "react";
import { Actions, Container, FormWrapper } from "./SecurityForm.styled";
import { Button, InputField } from "../../../common";
import { useStores } from "../../../../stores";
import { observer } from "mobx-react";
import { useFormik } from "formik";
import { useRef } from "react";

export const SecurityForm = observer(() => {
	const { authStore, notificationsStore } = useStores();
	const { userStore } = authStore;
	const formRef = useRef<HTMLFormElement>(null);

	const formik = useFormik({
		initialValues: {},
		onSubmit: async (values, { resetForm }) => {
			try {
				await userStore.update(values);
				if (formRef.current) {
					formRef.current.reset();
				}
			} catch (e: any) {
				notificationsStore.error({
					message: e.errors.join("<br/>"),
				});
			}
		},
	});

	return (
		<Container>
			<FormWrapper ref={formRef} onSubmit={formik.handleSubmit}>
				<InputField
					small
					label={"Current Password"}
					placeholder={"Your old password"}
					name={"old_password"}
					onChange={formik.handleChange}
				/>

				<InputField
					small
					label={"New Password"}
					placeholder={"Your new password"}
					name={"password"}
					onChange={formik.handleChange}
				/>

				<InputField
					small
					label={"Repeat New Password "}
					placeholder={"Your repeat password"}
					name={"password_confirmation"}
					onChange={formik.handleChange}
				/>
				<Actions data-action>
					<Button type={"submit"}>Update</Button>
				</Actions>
			</FormWrapper>
		</Container>
	);
});
