import { action, computed, makeObservable, observable } from "mobx";
import { INSTRUMENT_NAME, ServiceName } from "../../constants";
import { AboutServiceI } from "../../interfaces";

export class PageStore {
	@observable service: ServiceName = ServiceName.School;
	@observable instrument: INSTRUMENT_NAME = INSTRUMENT_NAME.GUITAR;
	@observable current_service: "beginner" | "advanced" | "professional" = "beginner";

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setCurrentService(value: "beginner" | "advanced" | "professional") {
		this.current_service = value;
	}

	@action.bound
	setService(value: ServiceName) {
		this.service = value;
	}

	@action.bound
	setInstrument(value: INSTRUMENT_NAME) {
		this.instrument = value;
	}

	@computed
	get currentServiceInstrument(): string {
		return `${this.service}-${this.instrument}`;
	}

	@computed
	get title(): string {
		const title: { [key: string]: string } = {
			"beginner-saxophone": "Let's start your saxophone<br/> playing together",
			"beginner-keyboard": "Let's start your keyboard<br/> playing together",
			"beginner-guitar": "Let's start your music<br/> together",

			"advanced-saxophone": "Take your saxophone<br/> playing to the next level",
			"advanced-keyboard": "Take your keyboard playing to<br/> the next level",
			"advanced-guitar": "Take your guitar playing to<br/> the next level",

			"professional-saxophone": "Become a saxophone<br/> professional",
			"professional-keyboard": "Become a keyboard<br/> professional",
			"professional-guitar": "Become a guitar<br/> professional",
		};

		return title[this.currentServiceInstrument] || "Not found";
	}

	@computed
	get subTitle() {
		const subTitle: { [key: string]: string } = {
			"beginner-saxophone": "Your Saxophone  |  The Internet  |  30+ min a day",
			"beginner-keyboard": "Your Keyboard  |  The Internet  |  30+ min a day",
			"beginner-guitar": "Your Guitar  |  The Internet  |  30+ min a day",

			"advanced-saxophone": "Your Saxophone  |  The Internet  |  45+ min a day",
			"advanced-keyboard": "Your Keyboard  |  The Internet  |  45+ min a day",
			"advanced-guitar": "Your Guitar  |  The Internet  |  45+ min a day",

			"professional-saxophone": "Your Saxophone  |  The Internet  |  60+ min a day",
			"professional-keyboard": "Your Keyboard  |  The Internet  |  60+ min a day",
			"professional-guitar": "Your Guitar  |  The Internet  |  60+ min a day",
		};

		return subTitle[this.currentServiceInstrument] || "Not found";
	}

	@computed
	get header() {
		const headers: { [key: string]: string } = {
			"beginner-saxophone": "This saxophone beginner is for you if:",
			"beginner-keyboard": "This keyboard beginner is for you if:",
			"beginner-guitar": "This guitar beginner is for you if:",

			"advanced-saxophone": "This saxophone advanced is for you if:",
			"advanced-keyboard": "This keyboard advanced is for you if:",
			"advanced-guitar": "This guitar advanced is for you if:",

			"professional-saxophone": "This saxophone professional is for you if:",
			"professional-keyboard": "This keyboard professional is for you if:",
			"professional-guitar": "This guitar professional is for you if:",
		};

		return headers[this.currentServiceInstrument] || "";
	}

	@computed
	get columns() {
		const columns: { [key: string]: Array<string> } = {
			"beginner-guitar": [
				"You already know ho to hold the guitar in your hands, but understand that it is not enough.",
				"You can play several chords and want to move further.",
				"You want to play your favorite songs and compose your own ones.",
			],
			"beginner-keyboard": [
				"You already know ho to hold the guitar in your hands, but understand that it is not enough.",
				"You can play several chords and want to move further.",
				"You want to play your favorite songs and compose your own ones.",
			],
			"beginner-saxophone": [
				"You already know ho to hold the guitar in your hands, but understand that it is not enough.",
				"You can play several chords and want to move further.",
				"You want to play your favorite songs and compose your own ones.",
			],

			"advanced-guitar": [
				"You want to play the guitar more consciously and technically",
				"You are thinking about obtaining systematic knowledge",
				"You strive to create new music using new methods and ways",
			],
			"advanced-keyboard": [
				"You want to play the keyboard more consciously and technically",
				"You are thinking about obtaining systematic knowledge",
				"You strive to create new music using new methods and ways",
			],
			"advanced-saxophone": [
				"You want to play the saxophone more consciously and technically",
				"You are thinking about obtaining systematic knowledge",
				"You strive to create new music using new methods and ways",
			],

			"professional-guitar": [
				"Your guitar level is alreday high enough, but you feel that you've hit the сeiling and can't move further to become a true professional",
				"You are willing to enhance your theoretical and practical guitar knowledge to the maximum extent",
				"You want to learn how to instantly improvise and compose absolutely unique music",
			],
			"professional-keyboard": [
				"Your keyboard level is alreday high enough, but you feel that you've hit the сeiling and can't move further to become a true professional",
				"You are willing to enhance your theoretical and practical keyboard knowledge to the maximum extent",
				"You want to learn how to instantly improvise and compose absolutely unique music",
			],
			"professional-saxophone": [
				"Your saxophone level is alreday high enough, but you feel that you've hit the сeiling and can't move further to become a true professional",
				"You are willing to enhance your theoretical and practical saxophone knowledge to the maximum extent",
				"You want to learn how to instantly improvise and compose absolutely unique music",
			],
		};
		return columns[this.currentServiceInstrument] || [];
	}

	@computed
	get playingImage() {
		const data: { [key: string]: string } = {
			"beginner-saxophone": "playing-saxophone-school",
			"beginner-keyboard": "playing-keyboard-school",
			"beginner-guitar": "playing-guitar-school",

			"advanced-saxophone": "playing-saxophone-college",
			"advanced-keyboard": "playing-keyboard-college",
			"advanced-guitar": "playing-guitar-college",

			"professional-saxophone": "playing-saxophone-university",
			"professional-keyboard": "playing-keyboard-university",
			"professional-guitar": "playing-guitar-university",
		};

		return data[this.currentServiceInstrument] || "";
	}

	@computed
	get headerPlaying() {
		const data: { [key: string]: string } = {
			beginner: "The beginning of a bright music journey ",
			advanced: "Pursuing the bright music future",
			university: "Pursuing the bright music future",
		};

		return data[this.service] || "";
	}

	@computed
	get textPlaying() {
		const data: { [key: string]: Array<string> } = {
			"beginner-saxophone": [
				"MUSICABINET | SAXOPHONE BEGINNER is made for those, who are eager to get high quality fundamental music education as fast as possible.",
				"It is for those, who want to invest their time & money into the future smartly without having to spend the last dime on it. ",
			],
			"beginner-keyboard": [
				"MUSICABINET | KEYS BEGINNER is made for those, who are eager to get high quality fundamental music education as fast as possible.",
				"It is for those, who want to invest their time & money into the future smartly without having to spend the last dime on it. ",
			],
			"beginner-guitar": [
				"MUSICABINET | GUITAR BEGINNER is made for those, who are eager to get high quality fundamental music education as fast as possible.",
				"It is for those, who want to invest their time & money into the future smartly without having to spend the last dime on it. ",
			],

			"advanced-saxophone": [
				"MUSICABINET | SAXOPHONE ADVANCED was created for those who want to get a fundamental musical education quickly and efficiently.",
				"This is a story for someone who is ready to wisely invest their time in their future, without giving up the last shirt.",
			],
			"advanced-keyboard": [
				"MUSICABINET | KEYBOARD ADVANCED was created for those who want to get a fundamental musical education quickly and efficiently.",
				"This is a story for someone who is ready to wisely invest their time in their future, without giving up the last shirt.",
			],
			"advanced-guitar": [
				"MUSICABINET | GUITAR ADVANCED was created for those who want to get a fundamental musical education quickly and efficiently.",
				"This is a story for someone who is ready to wisely invest their time in their future, without giving up the last shirt.",
			],

			"professional-saxophone": [
				"MUSICABINET | SAXOPHONE PROFESSIONAL is made for those, who are striving to get the most out of fundamental music education in a systematic way.",
				"It is for those, who want to invest time & money into the future smartly in order to fullfill the dream of becoming a true professional.",
			],
			"professional-keyboard": [
				"MUSICABINET | KEYBOARD PROFESSIONAL is made for those, who are striving to get the most out of fundamental music education in a systematic way.",
				"It is for those, who want to invest time & money into the future smartly in order to fullfill the dream of becoming a true professional.\n",
			],
			"professional-guitar": [
				"MUSICABINET | GUITAR PROFESSIONAL is made for those, who are striving to get the most out of fundamental music education in a systematic way.",
				"It is for those, who want to invest time & money into the future smartly in order to fullfill the dream of becoming a true professional.",
			],
		};

		return data[this.currentServiceInstrument] || [];
	}

	@computed
	get familiarHeader() {
		const data: { [key: string]: string } = {
			beginner: "Get familiar with MUSICABINET | BEGINNER within 14 days",
			advanced: "Get familiar with MUSICABINET | ADVANCED within 14 days",
			university: "Get familiar with MUSICABINET | PROFESSIONAL within 14 days",
		};

		return data[this.service] || "";
	}

	@computed
	get grandChartId() {
		const data: { [key: string]: number } = {
			"beginner-saxophone": 4,
			"beginner-keyboard": 2,
			"beginner-guitar": 1,

			"advanced-saxophone": 6,
			"advanced-keyboard": 5,
			"advanced-guitar": 3,

			"professional-saxophone": 9,
			"professional-keyboard": 7,
			"professional-guitar": 8,
		};

		return data[this.currentServiceInstrument] || 0;
	}

	@computed
	get aboutService() {
		const data: AboutServiceI = {
			beginner: [
				{
					count: 3,
					title: "courses",
					description: "Consists of 3 courses.  There are 6 modules in each course.",
				},
				{
					count: 149,
					title: "lessons",
					description: "A total of 149 lessons for studying music theory and practical exercises",
				},
				{
					count: 45,
					title: "backing tracks",
					description: "Practice to consolidate the knowledge gained or just for fun :-)",
				},
			],
			advanced: [
				{
					count: 4,
					title: "courses",
					description: "Consists of 4 courses from simple to complex. Each course includes 4 modules.",
				},
				{
					count: 192,
					title: "lessons",
					description: "A total of 192 lessons for learning music theory and practice",
				},
				{
					count: 75,
					title: "backing tracks",
					description: "Practice to consolidate the knowledge gained or just for fun :-)",
				},
			],
			university: [
				{
					count: 7,
					title: "courses",
					description:
						"Consists of 7 courses (from simple to complex).  There are 5 educational modules and 2 improvisational modules in each course.",
				},
				{
					count: 1001,
					title: "lessons",
					description: "A total of 1001 lessons for studying music theory and practical exercises",
				},
				{
					count: "300+",
					title: "backing tracks",
					description: "Practice to consolidate the knowledge gained to become a real professional",
				},
			],
		};

		return data[this.service] || [];
	}

	@computed
	get gadgetImage() {
		const paths: { [key: string]: string } = {
			beginner: "/images/gadjects/school-gadjects",
			advanced: "/images/gadjects/college-gadjects",
			university: "/images/gadjects/university-gadjects",
		};

		return paths[this.service] || "";
	}

	@computed
	get currentServiceMap(): ServiceName {
		const mapping = {
			beginner: ServiceName.School,
			advanced: ServiceName.College,
			professional: ServiceName.University,
		};

		return mapping[this.current_service];
	}
}
