import { SeoContentI } from "../interfaces";

export const SEO: { [key: string]: SeoContentI } = {
	"beginner-guitar": {
		title: "Guitar Beginner | MUSICABINET | Online Guitar Education Platform",
		description:
			"Let's start your music together. Comprehensive Guitar Education. Learn to play the guitar 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"beginner-keyboard": {
		title: "Keyboard Beginner | MUSICABINET | Online Keyboard Education Platform",
		description:
			"Let's start your keyboard playing together. Comprehensive Keyboard Education. Learn to play the keyboard 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"beginner-saxophone": {
		title: "Saxophone Beginner | MUSICABINET | Online Keyboard Education Platform",
		description:
			"Let's start your saxophone playing together. Comprehensive Saxophone Education. Learn to play the saxophone 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"advanced-guitar": {
		title: "Guitar Advanced | MUSICABINET | Online Guitar Education Platform",
		description:
			"Take your guitar playing to the next level. Comprehensive Guitar Education. Learn to play the guitar 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"advanced-keyboard": {
		title: "Keyboard Advanced | MUSICABINET | Online Keyboard Education Platform",
		description:
			"Take your keyboard playing to the next level. Comprehensive Keyboard Education. Learn to play the keyboard 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"advanced-saxophone": {
		title: "Saxophone Advanced | MUSICABINET | Online Keyboard Education Platform",
		description:
			"Take your saxophone playing to the next level. Comprehensive Saxophone Education. Learn to play the saxophone 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"professional-guitar": {
		title: "Guitar Professional | MUSICABINET | Online Guitar Education Platform",
		description:
			"Become a guitar professional. Comprehensive Guitar Education. Learn to play the guitar 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"professional-keyboard": {
		title: "Keyboard Professional | MUSICABINET | Online Keyboard Education Platform",
		description:
			"Become a keyboard professional. Comprehensive Keyboard Education. Learn to play the keyboard 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
	"professional-saxophone": {
		title: "Saxophone Professional | MUSICABINET | Online Keyboard Education Platform",
		description:
			"Become a saxophone professional. Comprehensive Saxophone Education. Learn to play the saxophone 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals.",
	},
};
