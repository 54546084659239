import { action, computed, makeObservable, observable } from "mobx";
import { GrandChartMyDay, GrandChartMyDayProgress, GrandChartMyDayProgressData } from "../../../interfaces";
import { ServiceStore } from "../../services/service.store";
import { GrandChartModuleStore } from "../grand-chart-module.store";
import dayjs from "dayjs";
import { CourseStore } from "../../courses/course.store";
import { InstrumentStore } from "../../instruments/instrument.store";
import { LessonStore } from "../../lessons/lesson.store";

export class GrandChartMyDayStore implements GrandChartMyDay {
	@observable id = 0;
	@observable sort = 0;
	@observable grand_chart_id = 0;
	@observable course_id = 0;
	@observable total_time_minute = 0;
	@observable total_progress_minute_passed = 0;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable service = new ServiceStore(null);
	@observable instrument = new InstrumentStore(null);
	@observable grand_chart_modules: GrandChartModuleStore[] = [];
	@observable course = new CourseStore(null);
	@observable last_lesson = new LessonStore(null);

	private listProgress: GrandChartMyDayProgress = {};

	constructor(initialData: GrandChartMyDay | null, listProgress: GrandChartMyDayProgress) {
		makeObservable(this);

		this.listProgress = listProgress;

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get nameInstrument() {
		return `${this.instrument.name}`;
	}

	@computed
	get percentPassed(): number {
		return 0;

		/*if (this.total_progress_minute_passed === 0 || this.total_time_minute === 0) {
			return 0;
		}

		return Number(((this.total_progress_minute_passed * 100) / this.total_time_minute).toFixed(0));*/
	}

	@computed
	get lastLessonData(): GrandChartMyDayProgressData | null {
		if (this.last_lesson && this.last_lesson.uuid) {
			return {
				grand_chart_module_id: this.last_lesson.grand_chart_group_lesson.grand_chart_module_id,
				count_minute: 0,
				date: dayjs(),
				lesson_name: this.last_lesson.name,
				lesson_uuid: this.last_lesson.uuid,
				name_group_lesson: this.last_lesson.nameLessonGroup,
			};
		}

		if (this.grand_chart_modules.length > 0) {
			const grand_chart_module_id = this.grand_chart_modules[0].id;
			const findKeyProgress = `${this.id}-${grand_chart_module_id}`;

			// Находим в прогрессе урок
			if (this.listProgress[findKeyProgress]) {
				return this.listProgress[findKeyProgress];
			}

			return null;
		}

		return null;
	}

	@action
	fillStore(data: GrandChartMyDay) {
		const {
			id,
			sort,
			grand_chart_id,
			course_id,
			total_time_minute,
			total_progress_minute_passed,
			created_at,
			updated_at,
			service,
			instrument,
			grand_chart_modules,
			course,
			last_lesson,
		} = data;

		this.id = id;
		this.sort = sort;
		this.grand_chart_id = grand_chart_id;
		this.course_id = course_id;
		this.total_time_minute = total_time_minute;
		this.total_progress_minute_passed = total_progress_minute_passed;
		this.created_at = created_at;
		this.updated_at = updated_at;
		this.service = new ServiceStore(service);
		this.instrument = new InstrumentStore(instrument);
		this.grand_chart_modules = (grand_chart_modules || []).map(
			(grand_chart_module) => new GrandChartModuleStore(grand_chart_module),
		);
		this.course = new CourseStore(course);
		this.last_lesson = new LessonStore(last_lesson);
	}
}
