import { Container } from "./Ratings.styled";
import { ReactComponent as StarIcon } from "./images/star.svg";

interface RatingsProps {
	count: number;
}

export const Ratings = ({ count }: RatingsProps) => {
	return (
		<Container>
			{[...Array(count).keys()].map((index) => (
				<StarIcon key={index} />
			))}
		</Container>
	);
};
