import { AuthStore } from "./auth/auth.store";
import { SettingsStore } from "./settings/settings.store";
import { UserStore } from "./user/user.store";
import { ToasterStore } from "./toaster/toaster.store";
import React from "react";
import { ServicesStore } from "./services/services.store";
import { GrandChartsStore } from "./grand-charts/grand-charts.store";
import { LessonStore } from "./lessons/lesson.store";
import { WebsocketStore } from "./websocket/websocket.store";
import { MetronomeStore } from "./metronome/metronome.store";
import { LessonProgressStore } from "./lessons/lesson-progress.store";
import { AlarmClockStore } from "./alarm-clock/alarm-clock.store";
import { AudioPlayerStore } from "./audio-player/audio-player.store";
import { MenuStore } from "./menu/menu.store";
import { GrandChartsMyDayStore } from "./grand-charts/grand-charts-my-day/grand-charts-my-day.store";
import { MainStatisticsMyDayStore } from "./main-statistics/main-statistics-my-day.store";
import { MainStatisticsMonthStore } from "./main-statistics/main-statistics-month.store";
import { ProductInstrumentsStore } from "./product-instruments/product-instruments.store";
import { ProductsStore } from "./products/products.store";
import { NotificationsStore } from "./notifications/notifications.store";
import { ScoreViewStore } from "./scores/score-view.store";
import { ViewerStore } from "./viewer/viewer.store";
import { HistoryStore } from "./history/history.store";
import { StripeStore } from "./stripe/stripe.store";
import { PageStore } from "./page/page.store";
import { PurchasesStore } from "./purchases/purchases.store";
import { NextLessonStore } from "./next-lesson/next-lesson.store";
import { GeoPluginStore } from "./geo-plugin/geo-plugin.store";
import { TutorialFrameStore } from "./tutorial-frame/tutorial-frame.store";
import { ViewIframeStore } from "./view-iframe/view-iframe.store";
import { BannersStore } from "./banners/banners.store";
import { StatisticsGrandChartStore } from "./statistics";
import { AccessLimitedStore } from "./access";
import { CategoriesStore } from "./categories";
import { DurationsStore } from "./durations";
import { GoodsStore } from "./goods";
import { PaymentStore } from "./payment/payment.store";
import { AiStore } from "./ai";

export class RootStore {
	public settingsStore: SettingsStore;
	public authStore: AuthStore;
	public userStore: UserStore;
	public toasterStore: ToasterStore;
	public servicesStore: ServicesStore;
	public grandCharts: GrandChartsStore;
	public lessonStore: LessonStore;
	public websocketStore: WebsocketStore;
	public metronomeStore: MetronomeStore;
	public lessonProgressStore: LessonProgressStore;
	public alarmClockStore: AlarmClockStore;
	public audioPlayerStore: AudioPlayerStore;
	public menuStore: MenuStore;
	public grandChartsMyDayStore: GrandChartsMyDayStore;
	public mainStatisticsMyDayStore: MainStatisticsMyDayStore;
	public mainStatisticsMonthStore: MainStatisticsMonthStore;
	public productInstrumentsStore: ProductInstrumentsStore;
	public productsStore: ProductsStore;
	public notificationsStore: NotificationsStore;
	public scoreViewStore: ScoreViewStore;
	public viewerStore: ViewerStore;
	public historyStore: HistoryStore;
	public stripeStore: StripeStore;
	public pageStore: PageStore;
	public purchasesStore: PurchasesStore;
	public nextLessonStore: NextLessonStore;
	public geoPluginStore: GeoPluginStore;
	public tutorialFrameStore: TutorialFrameStore;
	public viewIframeStore: ViewIframeStore;
	public bannersStore: BannersStore;
	public statisticsStore: StatisticsGrandChartStore;
	public accessLimitedStore: AccessLimitedStore;
	public categoriesStore: CategoriesStore;
	public durationsStore: DurationsStore;
	public goodsStore: GoodsStore;

	public paymentStore: PaymentStore;

	public aiStore: AiStore;

	constructor() {
		this.settingsStore = new SettingsStore();
		this.authStore = new AuthStore(null);
		this.userStore = new UserStore(null);
		this.toasterStore = new ToasterStore();
		this.servicesStore = new ServicesStore();
		this.grandCharts = new GrandChartsStore();
		this.lessonStore = new LessonStore(null);
		this.websocketStore = new WebsocketStore(this);
		this.metronomeStore = new MetronomeStore();
		this.lessonProgressStore = new LessonProgressStore(this);
		this.alarmClockStore = new AlarmClockStore();
		this.audioPlayerStore = new AudioPlayerStore(this);
		this.menuStore = new MenuStore();
		this.grandChartsMyDayStore = new GrandChartsMyDayStore(this);
		this.mainStatisticsMyDayStore = new MainStatisticsMyDayStore();
		this.mainStatisticsMonthStore = new MainStatisticsMonthStore();
		this.productInstrumentsStore = new ProductInstrumentsStore();
		this.productsStore = new ProductsStore();
		this.notificationsStore = new NotificationsStore();
		this.scoreViewStore = new ScoreViewStore();
		this.viewerStore = new ViewerStore();
		this.historyStore = new HistoryStore();
		this.stripeStore = new StripeStore();
		this.pageStore = new PageStore();
		this.purchasesStore = new PurchasesStore();
		this.nextLessonStore = new NextLessonStore();
		this.geoPluginStore = new GeoPluginStore();
		this.tutorialFrameStore = new TutorialFrameStore();
		this.viewIframeStore = new ViewIframeStore();
		this.bannersStore = new BannersStore();
		this.statisticsStore = new StatisticsGrandChartStore();
		this.accessLimitedStore = new AccessLimitedStore();
		this.categoriesStore = new CategoriesStore();
		this.durationsStore = new DurationsStore();
		this.goodsStore = new GoodsStore();
		this.paymentStore = new PaymentStore();
		this.aiStore = new AiStore();
	}
}

let store: RootStore;

let StoreContext = React.createContext(new RootStore());

export const useStores = (): RootStore => React.useContext(StoreContext);

export const RootStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const root = store ?? new RootStore();
	return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
};
